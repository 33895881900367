import Table, { TableActionsButton } from '@app/components/UI/Table';
import Pagination from '@app/components/UI/Pagination';
import { toChunks } from '@app/utils/array';
import ListServices, { Service } from '@app/pages/Admin/Service/ListServices/ListServices';
import { route } from '@app/router/generator';
import DropMenu from '@app/components/UI/DropMenu';
import Link from '@app/components/Router/Link';
import UpdateService from '@app/pages/Admin/Service/UpdateService/UpdateService';
import { WithPreviousUrl } from '@app/hooks/usePreviousUrlLocationState';
import Dot from '@app/components/UI/Icon/Dot';
import { useSortHook } from '@app/hooks/useSortHook';
import { compareStrings } from '@app/utils/sort';
import { useCallback, useMemo } from 'react';

interface Props {
  services: readonly Service[]
  currentPage: number
  perPage?: number
}

export default function ServicesTable({
  services,
  currentPage,
  perPage = 10,
  previousUrl,
}: WithPreviousUrl<Props>) {
  const { sortColumn, sortOrder, handleSort } = useSortHook();
  const sortSettings = {
    'Intitulé': true,
    'Categorie': true,
  };

  const getSortedServices = useCallback(() => {
    return [...services].sort((a, b) => {
      switch (sortColumn) {
        case 'Intitulé':
          return compareStrings(a.name, b.name, sortOrder);
        case 'Categorie':
          return compareStrings(a.category.name, b.category.name, sortOrder);
        default:
          return 0;
      }
    });
  }, [services, sortColumn, sortOrder]);

  const sortedServices = useMemo(() => getSortedServices(), [getSortedServices]);

  const pages = toChunks(sortedServices, perPage);
  const pageServices = pages[currentPage - 1] ?? [];

  return <>
    <Table
      headers={[
        'Intitulé',
        'Categorie',
        'Actif',
        'Visible',
        '', // Actions
      ]}
      onHeaderClick={handleSort}
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      sortSettings={sortSettings}
      rows={pageServices.map(service => <TableItem
        previousUrl={previousUrl}
        key={service.uid}
        service={service}
      />)}
    />

    <Pagination current={currentPage} pages={Object.keys(pages).map(pageIdx => route(ListServices, {}, {
      page: String(parseInt(pageIdx) + 1),
    }))} />
  </>;
}

function TableItem({ service, previousUrl }: WithPreviousUrl<{
  service: Service
}> ) {
  return <tr>
    <td>{service.name}</td>
    <td>{service.category.name}</td>
    <td>{service.isActive ? <Dot variant="active"> Oui</Dot> : <Dot variant="inactive"> Non</Dot>} </td>
    <td>{service.isVisible ? <Dot variant="active"> Oui</Dot> : <Dot variant="inactive"> Non : changez pour une catégorie active</Dot>} </td>
    <td className="text-right">
      <TableItemMenu uid={service.uid} previousUrl={previousUrl}/>
    </td>
  </tr>;
}

function TableItemMenu({ uid, previousUrl }: WithPreviousUrl<{ uid: string }>) {
  return <DropMenu>
    <DropMenu.Trigger>
      <TableActionsButton />
    </DropMenu.Trigger>
    <DropMenu.Items>
      <DropMenu.Item>
        <Link to={route(UpdateService, { uid } )} state={{ previousUrl }}>
          Modifier
        </Link>
      </DropMenu.Item>
    </DropMenu.Items>
  </DropMenu>;
}
