import Select from '@app/components/UI/Form/Select';
import React from 'react';

export type Uid = string;

type Props = Omit<React.ComponentProps<typeof Select<string>>, 'options'>;


export default function SelectRole({
  id,
  label,
  disabled,
  value,
  onChangedValue,
  errors,
}: Props) {
  const options  = [{ label: 'Exécutant', value: 'provider' }, { label: 'Demandeur', value: 'issuer' }];

  return <Select<string>
    id={id}
    label={label}
    options={options}
    disabled={disabled}
    value={value}
    onChangedValue={onChangedValue}
    errors={errors}
  />;
}
