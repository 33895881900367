import { ChangeEvent } from 'react';
import clsx from 'clsx';
import ErrorHelp from '@app/components/UI/Form/ErrorHelp';
import { FormErrors, FormUtils } from '@app/components/UI/Form';
import { Label } from '@app/components/UI/Form/Label';
import FormControl from '@app/components/UI/Form/FormControl';
import InputWrap from '@app/components/UI/Form/InputWrap';

interface Props {
  // Textarea
  id: string
  label: string
  required?: boolean
  disabled?: boolean
  hint?: string
  placeholder?: string

  // props overrides
  LabelProps?: JSX.IntrinsicElements['label']

  // Value
  value?: string | null
  onChangedValue?: (value: string | null, e: ChangeEvent<HTMLTextAreaElement>) => void
  /** @default true */
  normalizeEmptyStringToNull?: boolean

  // Errors
  errors?: FormErrors
}

function normalizedValue(value: string | null) {
  return value ?? '';
}

export default function TextArea({
  id,
  label,
  required = false,
  disabled = false,
  hint,
  placeholder,
  value = null,
  onChangedValue,
  errors = [],
  normalizeEmptyStringToNull = true,
  LabelProps = {},
}: Props) {
  const hasError = FormUtils.hasError(errors);

  function onChange(event: ChangeEvent<HTMLTextAreaElement>): void {
    if (!onChangedValue) {
      return;
    }

    let normalizedValue: (string | null) = event.target.value;

    if (normalizeEmptyStringToNull && '' === normalizedValue) {
      normalizedValue = null;
    }

    return onChangedValue(normalizedValue, event);
  }

  return <FormControl type="input" hasError={hasError} disabled={disabled}>
    <Label required={required} htmlFor={id} label={label} hint={hint} {...LabelProps} />

    <InputWrap>
      <textarea
        id={id}
        className={clsx('input', {
          'input--error': hasError,
        })}
        {...(hasError ? {
          'aria-describedby': `${id}-desc-error`,
        } : {})}
        required={required}
        value={normalizedValue(value)}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}></textarea>
    </InputWrap>

    <ErrorHelp id={id} errors={errors} />
  </FormControl>;
}
