import { useState } from 'react';
import Tag from '@app/components/UI/Tag';
import Avatar from '@app/components/UI/Avatar/Avatar';
import Button from '@app/components/UI/Button';
import SearchModal from '@app/pages/Member/Search/SearchModal';
import tw from 'twin.macro';
import { User } from '@app/pages/Member/Search/Search';

interface Props {
  user: User
}

export default function MemberProfileCard({ user }: Props) {
  const [openedModalUserUid, setOpenedModalUserUid] = useState<string|null>(null);

  return <>
    <Card key={user.uid}>
      <div>
        <UserProfile>
          <Avatar />
          <div className="flex flex-col font-bold">
            <span className="text-lg">{user.civility} {user.firstname} {user.lastname}</span>
            <span className="uppercase">{user.postCode} {user.city}</span>
          </div>
        </UserProfile>
        <UserContact>
          <a href={`tel:${user.mobile}`}>{user.mobile}</a>
          <a href={`tel:${user.phone}`}>{user.phone}</a>
          <a href={`mailto:${user.email}`}>{user.email}</a>
        </UserContact>
        <ServicesStyled>
          {user.providedServices.length > 0 && <span>Cette personne propose</span>}
          <div className="flex gap-1 flex-wrap mt-1">
            {user.providedServices.map((service, index) => (
              <Tag title={service.name} key={index}  />
            ))}
          </div>
        </ServicesStyled>
      </div>
      <Button className="w-fit -ms-5" onClick={() => setOpenedModalUserUid(user.uid)}>En savoir plus</Button>
    </Card>

    <SearchModal user={user} opened={openedModalUserUid === user.uid} close={() => setOpenedModalUserUid(null)} />
  </>;
}

const Card = ({ children }) => {
  return (
    <CardStyled>
      {children}
    </CardStyled>
  );
};

const CardStyled = tw.div`
  border
  rounded-xl
  p-8
  flex
  flex-col
  justify-between
  lg:flex-[0_0_calc(33.33% - 20px)]
  w-full
  box-border
  m-[10px]
  bg-white
`;

const UserProfile = tw.div`
 mb-3
 flex
 gap-2
 items-center
`;

const UserContact = tw.div`
  mb-3
  flex
  flex-col
`;

const ServicesStyled = tw.div`
  mb-3
  flex
  flex-col
`;
