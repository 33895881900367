import { declareMemberRoute } from '@app/router/router';
import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { useAuthContext } from '@app/hooks/useAuthContext';
import { useNotFoundHandler } from '@app/components/ErrorBoundary';
import { useQuery } from '@apollo/client';
import MeQuery from '@graphql/query/user/Me.graphql';
import { useMutation } from '@app/api/apollo/useMutation';
import UpdateMeMutation from '@graphql/mutation/user/UpdateMe.graphql';
import { useMemo } from 'react';
import { errorsByPath } from '@app/api/errors';
import { AppGraphQLError } from '@app/api/errors/GraphQLErrorCodes';
import { onMutateError } from '@graphql/utils';
import UnexpectedError from '@app/errors/UnexpectedError';
import { trans } from '@app/translations';
import Alert from '@app/components/UI/Alert';
import ProfileForm, { UserData } from '@app/components/Form/Member/Profile/ProfileForm';

interface MutationResponse {
  User: {
    update: {
      uid: string
    }
  }
}

interface Service {
  uid: string
}

interface User {
  uid: string
  civility: string
  email: string
  firstname: string
  lastname: string
  birthYear: number
  address: string
  city: string
  postCode: string
  phone: string | null
  mobile: string | null
  comment: string | null
  details: string | null
  providedServices: Service[]
  searchedServices: Service[]
}

interface MeQueryResponse {
  User: {
    me: User
  }
}

export default declareMemberRoute(function Profile() {
  useDocumentTitle('Profile');

  const { profile } = useAuthContext();
  const notFoundHandler = useNotFoundHandler();
  const uid = profile?.uid;

  const query = useQuery<MeQueryResponse>(MeQuery, {
    variables: { uid },
    context: {
      // On GraphQL Not Found error, show a Not Found page
      onNotFound: notFoundHandler,
    },
  });

  const [mutate, mutationState] = useMutation<MutationResponse>(UpdateMeMutation);
  const mappedErrors = useMemo(() => {
    const error = mutationState.error;

    return {
      __root: error ? 'Une erreur est survenue lors de la soumission du formulaire.' : undefined,
      ...(error ? errorsByPath(error.graphQLErrors as AppGraphQLError[]) : {}),
    };
  }, [mutationState.error]);

  async function submit(payload: UserData) {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    mutate({
      variables: { uid, payload },
    }).catch(onMutateError);
  }

  if (query.error) {
    throw new UnexpectedError(query.error.message, query.error);
  }

  if (query.loading) {
    // TODO: better loading state, with a dedicated skeleton or generic loader?
    return <p>{trans('common.loading')}</p>;
  }

  const user = query.data!.User.me;

  return <div className="w-full">
    {mutationState.called && mutationState.data?.User && <Alert variant="success">
       Modifié avec succès.
    </Alert>}

    <ProfileForm onSubmit={submit} errors={mappedErrors} initialData={{
      uid: user.uid,
      civility: user.civility,
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname,
      birthYear: user.birthYear,
      address: user.address,
      city: user.city,
      postCode: user.postCode,
      phone: user.phone,
      mobile: user.mobile,
      comment: user.comment,
      details: user.details,
      providedServices: user.providedServices.map(service => service.uid),
      searchedServices: user.searchedServices.map(service => service.uid),
    }} />

  </div>;
}, '/profile');
