import { MenuProps } from '@mui/base/Menu';
import { cloneElement, HTMLAttributes, MouseEvent, ReactElement, useId, useState } from 'react';
import DropMenuItems, { DropMenuItem } from '@app/components/UI/DropMenu/DropMenuItems';
import { withDisplayName } from '@app/utils/displayName';
import { findSlotOfType } from '@app/utils/slots';
import tw from 'twin.macro';
import Button from '@app/components/UI/Button';

interface Props extends MenuProps {
  children: [
    // Unfortunately, not working as expected with JSX (but let's keep for doc purposes):
    // https://react-typescript-cheatsheet.netlify.app/docs/advanced/patterns_by_usecase#what-you-cannot-do
    ReactElement<unknown, typeof DropMenuTrigger>,
    ReactElement<unknown, typeof DropMenuItems>,
  ]
}

/**
 * @see https://mui.com/base-ui/react-menu/#usage For implementation details.
 */
function DropMenu({
  children,
}: Props) {
  const id = useId();
  const [anchorEl, setAnchorEl] = useState<EventTarget|null>(null);
  const isOpened = Boolean(anchorEl);

  const handleClick = (event: MouseEvent) => setAnchorEl(anchorEl ? null : event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const DropMenuTriggerElement = findSlotOfType(children, DropMenuTrigger);

  if (!DropMenuTriggerElement) {
    throw new Error('You MUST provide a <DropMenu.Trigger> component as a child of <DropMenu>.');
  }

  const TriggerElement = cloneElement(DropMenuTriggerElement, {
    onClick: (e) => {
      DropMenuTriggerElement.props.onClick?.(e);
      handleClick(e);
    },
    'aria-controls': isOpened ? id : undefined,
    'aria-expanded': isOpened ? 'true' : 'false',
    'aria-haspopup': 'menu',
  });

  const DropMenuItemsElement = findSlotOfType(children, DropMenuItems);

  if (!DropMenuItemsElement) {
    throw new Error('You MUST provide a <DropMenu.Items> component as a child of <DropMenu>.');
  }

  return <StyledDropMenu>
    {/* Element where and by which the drop menu is triggered  */}
    {TriggerElement}
    {/* The rendered elements on showing the drop menu  */}
    <DropMenuItems.Slot
      id={id}
      {...DropMenuItemsElement.props}
      anchorEl={anchorEl}
      close={handleClose}
      isOpened={isOpened}
    >
      {DropMenuItemsElement}
    </DropMenuItems.Slot>
  </StyledDropMenu>;
}

const StyledDropMenu = tw.div`relative p-0 m-0`;

export const DropMenuButtonItem = tw(Button)`
  p-0
  m-0
  leading-tight
  text-lg
  text-light
`;

export function DropMenuTrigger({ children, ...remainingProps }: {
  children: ReactElement
} & HTMLAttributes<unknown>) {
  return cloneElement(children, { ...remainingProps });
}

// https://react-typescript-cheatsheet.netlify.app/docs/advanced/misc_concerns#namespaced-components
DropMenu.Trigger = withDisplayName(DropMenuTrigger, 'DropMenu.Trigger');
DropMenu.Items = withDisplayName(DropMenuItems, 'DropMenu.Items');
DropMenu.Item = withDisplayName(DropMenuItem, 'DropMenu.Item');

export default DropMenu;
