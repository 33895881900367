import Table from '@app/components/UI/Table';

import { Exchange } from '@app/models/types/Exchange';
import { UserProfile } from '@app/hooks/useAuthContext';
import { toChunks } from '@app/utils/array';
import { route } from '@app/router/generator';
import Pagination from '@app/components/UI/Pagination';
import Exchanges from '@app/pages/User/Exchange/ListExchanges/ListMyExchanges';
import { useSortHook } from '@app/hooks/useSortHook';
import { compareDates, compareNumbers, compareStrings } from '@app/utils/sort';
import { formatDate } from '@app/utils/dates';
import { useCallback, useMemo } from 'react';
import { renderDuration } from '@app/utils/duration';

interface Props {
  profile: UserProfile | null
  exchanges: Exchange[]
  currentPage: number
  perPage?: number
}

/**
 * Member exchanges Table
 */
export default function ExchangesTable({
  profile,
  exchanges,
  currentPage,
  perPage = 10,
}: Props) {
  const { sortColumn, sortOrder, handleSort } = useSortHook('Échangé le', 'desc');

  const sortSettings = {
    'Service échangé': true,
    'Échangé le': true,
    'Échangé avec': true,
    'Catégorie du service': true,
    'Nombre d’heure': true,
  };

  const getSortedExchanges = useCallback(() => {
    return [...exchanges].sort((a, b) => {
      switch (sortColumn) {
        case 'Service échangé':
          return compareStrings(a.service.name, b.service.name, sortOrder);
        case 'Échangé avec':
          if (a.provider) {
            return compareStrings(a.provider.email, b.provider.email, sortOrder);
          }
          return compareStrings(a.issuer.email, b.issuer.email, sortOrder);
        case 'Échangé le':
          return compareDates(a.date, b.date, sortOrder);
        case 'Nombre d’heure':
          return compareNumbers(a.duration, b.duration, sortOrder);
        default:
          return 0;
      }
    });
  }, [exchanges, sortColumn, sortOrder]);

  const sortedExchanges = useMemo(() => getSortedExchanges(), [getSortedExchanges]);

  const pages = toChunks(sortedExchanges, perPage);
  const pageExchanges = pages[currentPage - 1] ?? [];

  return (
    <>
      <Table headers={[
        'Service échangé',
        'Échangé le',
        'Échangé avec',
        'Nombre d’heure',
        'Mon rôle dans l’échange',
        'Commentaire',
      ]}
      onHeaderClick={handleSort}
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      sortSettings={sortSettings}
      rows={pageExchanges.map((exchange) => <TableItem
        profile={profile}
        key={exchange.uid}
        exchange={exchange}
      />)}
      />

      <Pagination current={currentPage} pages={Object.keys(pages).map(pageIdx => route(Exchanges, {}, {
        page: String(parseInt(pageIdx) + 1),
      }))} />
    </>
  );
}

function TableItem({ profile, exchange }) {
  const amIProvider = exchange.provider.uid === profile?.uid;

  return <tr>
    <td>{exchange.service.name}</td>
    <td>{formatDate(exchange.date)}</td>
    <td>{amIProvider ? exchange.issuer.email : exchange.provider.email}</td>
    <td>{renderDuration(exchange.duration)}</td>
    <td>{amIProvider ? 'Exécutant': 'Demandeur'}</td>
    <td>{exchange.comment}</td>
  </tr>;
}
