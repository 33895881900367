import Table, { TableActionsButton } from '@app/components/UI/Table';
import DropMenu from '@app/components/UI/DropMenu';
import Link from '@app/components/Router/Link';
import { route } from '@app/router/generator';
import UpdateExchange from '@app/pages/Admin/Exchange/UpdateExchange/UpdateExchange';
import ListExchange, { Exchange } from '@app/pages/Admin/Exchange/ListExchanges/ListExchanges';
import { WithPreviousUrl } from '@app/hooks/usePreviousUrlLocationState';
import { toChunks } from '@app/utils/array';
import Pagination from '@app/components/UI/Pagination';
import { formatDate } from '@app/utils/dates';
import { useSortHook } from '@app/hooks/useSortHook';
import { compareDates, compareNumbers, compareStrings } from '@app/utils/sort';
import { useCallback, useMemo } from 'react';
import { useMutation } from '@app/api/apollo/useMutation';
import DeleteExchangeMutation from '@graphql/mutation/exchange/DeleteExchange.graphql';
import { onDeleteExchange } from '@graphql/store/exchanges';
import tw from 'twin.macro';
import { DropMenuButtonItem } from '@app/components/UI/DropMenu/DropMenu';
import { renderDuration } from '@app/utils/duration';

interface Props {
  exchanges: readonly Exchange[]
  currentPage: number
  perPage?: number
}

interface MutationDeleteResponse {
  Exchange: {
    delete: string
  }
}

/**
 * Admin Exchanges Table.
 */
export default function ExchangesTable({
  exchanges,
  currentPage,
  perPage = 10,
  previousUrl,
}: WithPreviousUrl<Props>) {
  const { sortColumn, sortOrder, handleSort } = useSortHook();

  const sortSettings = {
    'Demandeur': true,
    'Exécutant': true,
    'Service': true,
    'Catégorie du service': true,
    'Date': true,
    'Durée': true,
  };
  const getSortedExchanges = useCallback(() => {
    return [...exchanges].sort((a, b) => {
      switch (sortColumn) {
        case 'Demandeur':
          return compareStrings(a.issuer.email, b.issuer.email, sortOrder);
        case 'Exécutant':
          return compareStrings(a.provider.email, b.provider.email, sortOrder);
        case 'Service':
          return compareStrings(a.service.name, b.service.name, sortOrder);
        case 'Catégorie du service':
          return compareStrings(
            a.service.category.name,
            b.service.category.name,
            sortOrder,
          );
        case 'Date':
          return compareDates(a.date, b.date, sortOrder);
        case 'Durée':
          return compareNumbers(a.duration, b.duration, sortOrder);
        default:
          return 0;
      }
    });
  }, [exchanges, sortColumn, sortOrder]);

  const sortedExchanges = useMemo(() => getSortedExchanges(), [getSortedExchanges]);

  const pages = toChunks(sortedExchanges, perPage);
  const pageExchanges = pages[currentPage - 1] ?? [];

  return (
    <>
      <Table
        headers={[
          'Demandeur',
          'Exécutant',
          'Service',
          'Catégorie du service',
          'Commentaire',
          'Date',
          'Durée',
          '',
        ]}
        onHeaderClick={handleSort}
        sortColumn={sortColumn}
        sortOrder={sortOrder}
        sortSettings={sortSettings}
        rows={pageExchanges.map((exchange) => (
          <TableItem previousUrl={previousUrl} key={exchange.uid} exchange={exchange} />
        ))}
      />

      <Pagination
        current={currentPage}
        pages={Object.keys(pages).map((pageIdx) =>
          route(ListExchange, {}, {
            page: String(parseInt(pageIdx) + 1),
          }),
        )}
      />
    </>
  );
}


function TableItem({ exchange, previousUrl }: WithPreviousUrl<{
  exchange: Exchange
}> ) {
  return <tr>
    <td>{exchange.issuer.email}</td>
    <td>{exchange.provider.email}</td>
    <td>{exchange.service.name}</td>
    <td>{exchange.service.category.name}</td>
    <td>{exchange.comment}</td>
    <td>{formatDate(exchange.date)}</td>
    <td>{renderDuration(exchange.duration)}</td>
    <td className="text-right">
      <TableItemMenu uid={exchange.uid} previousUrl={previousUrl}/>
    </td>
  </tr>;
}

const DropMenuDeleteButton = tw(DropMenuButtonItem)`hover:!text-danger`;

function TableItemMenu({ uid, previousUrl }: WithPreviousUrl<{ uid: string }>) {
  const [mutate] = useMutation<MutationDeleteResponse>(DeleteExchangeMutation, {
    update(cache, { data }){
      onDeleteExchange(cache, data!.Exchange.delete);
    },
  });

  function deleteExchange(uid: string) {
    // todo: UI modale && Styled button instead of <Link>
    if (window.confirm('Etes-vous sûr de vouloir supprimer cet échange définitivement ?')){
      mutate({
        variables: { uid },
      }).catch(
        () => window.alert('Une erreur inattendue est survenue.'),
      );
    }
  }

  return <DropMenu>
    <DropMenu.Trigger>
      <TableActionsButton />
    </DropMenu.Trigger>
    <DropMenu.Items>
      <DropMenu.Item>
        <Link to={route(UpdateExchange, { uid } )} state={{ previousUrl }}>
          Modifier
        </Link>
      </DropMenu.Item>
      <DropMenu.Item>
        <DropMenuDeleteButton onClick={() =>
          deleteExchange(uid)
        }>
          Supprimer
        </DropMenuDeleteButton>
      </DropMenu.Item>
    </DropMenu.Items>
  </DropMenu>;
}
