import styled from '@emotion/styled';
import tw from 'twin.macro';

const CardInfo = ({ number, label }) => {
  return (
    <CardContainer>
      <CardNumber>{number}</CardNumber>
      <CardLabel>{label}</CardLabel>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  ${tw`
    border
    rounded-xl
    p-8
    flex
    flex-col
    gap-1
    items-center
  `}
`;

const CardNumber = styled.div`
  ${tw`
    text-primary
    text-3xl
    font-bold
  `}
`;

const CardLabel = styled.div`
  ${tw`
    uppercase
    text-sm
  `}
`;
export default CardInfo;
