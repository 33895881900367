import { useMemo, useState } from 'react';
import { Label } from '@app/components/UI/Form/Label';
import styled from '@emotion/styled';
import tw from 'twin.macro';

interface Props {
  id: string
  label?: string
  min: number
  max: number
  minValue?: number | null
  maxValue?: number | null
  step?: number
  formatter?: (min: number, max: number) => string
  onChange?: (min: number, max: number) => void
}
export default function Range({
  id,
  label,
  min,
  max,
  minValue,
  maxValue,
  step = 1,
  formatter = (min, max) => `${min}-${max}`,
  onChange,
}: Props) {
  const [inputFrom, setInputFrom] = useState<number>(minValue ?? min);
  const [inputTo, setInputTo] = useState<number>(maxValue ?? max);

  const style = useMemo(() => ({
    right: `${100 - (Math.max(inputFrom, inputTo) - min) / (max - min) * 100}%`,
    left: `${(Math.min(inputFrom, inputTo) - min) / (max - min) * 100}%`,
  }), [inputFrom, inputTo, min, max]);

  return (
    <div className="w-full mb-10" id={id}>
      {label && <Label label={label} htmlFor={`${id}_min`} />}
      <div className="h-[5px] relative rounded mt-8">
        <div className="absolute translate-x-[45%] -top-8" style={style}>
          <span className="text-sm text-center text-primary whitespace-nowrap">{formatter(inputFrom, inputTo)}</span>
        </div>
        <span className="h-[5px] top-[50%] -z-0 translate-y-1/2 absolute rounded-full bg-primary"
          style={style}>
        </span>
      </div>
      <div className="relative">
        <InputStyled
          id={`${id}_min`}
          type="range"
          value={inputFrom}
          onChange={(event) => {
            const newValue = Math.min(event.target.valueAsNumber, inputTo - step);
            setInputFrom(newValue);
            onChange?.(newValue, inputTo);
          }}
          min={min}
          max={max}
          step={step}
        />
        <InputStyled
          id={`${id}_max`}
          type="range"
          value={inputTo}
          onChange={(event) => {
            const newValue = Math.max(event.target.valueAsNumber, inputFrom + step);
            setInputTo(newValue);
            onChange?.(inputFrom, newValue);
          }}
          min={min}
          max={max}
          step={step}
        />
        <div className="h-[5px] relative -z-10 bg-gray-300 rounded-xl"></div>
      </div>
      <div className="relative w-full text-sm text-primary">
        <span className="absolute left-0 -bottom-8">{min}</span>
        <span className="absolute right-0 -bottom-8">{max}</span>
      </div>
    </div>
  );
}

const InputStyled = styled.input`
  ${tw`
    p-0
    absolute
    w-full
    h-[5px]
    -top-[7px]
    pointer-events-none
    appearance-none
    border-0
    bg-transparent
  `}
  &::-webkit-slider-thumb {
    ${tw`
      h-[20px]
      w-[20px]
      translate-y-[30%]
      rounded-full
      bg-primary
      cursor-pointer
      pointer-events-auto
      appearance-none
    `}
  }
`;
