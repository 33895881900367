import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { useMemo } from 'react';
import { declareAdminRoute } from '@app/router/router';
import { useMutation } from '@app/api/apollo/useMutation';
import { useNavigate } from 'react-router-dom';
import { onMutateError } from '@graphql/utils';
import { errorsByPath } from '@app/api/errors';
import { AppGraphQLError } from '@app/api/errors/GraphQLErrorCodes';
import CreateExchangeMutation from '@graphql/mutation/exchange/CreateExchange.graphql';
import { onCreatedExchange } from '@graphql/store/exchanges';
import { route } from '@app/router/generator';
import usePreviousUrlLocationState from '@app/hooks/usePreviousUrlLocationState';
import ExchangeForm, { ExchangeData } from '@app/components/Form/Admin/Exchange/ExchangeForm';
import UpdateExchange from '@app/pages/Admin/Exchange/UpdateExchange/UpdateExchange';
import { ROUTE_CREATE_EXCHANGE } from '@app/paths';

interface MutationResponse {
  Exchange: {
    create: { uid: string }
  }
}

export default declareAdminRoute(function CreateExchange() {
  useDocumentTitle('Ajout d\'un échange');
  const { previousUrl } = usePreviousUrlLocationState();

  const navigate = useNavigate();
  const [mutate, { error }] = useMutation<MutationResponse>(CreateExchangeMutation, {
    update(cache, { data }) {
      onCreatedExchange(cache, data!.Exchange.create);
    },
  });

  const mappedErrors = useMemo(() => ({
    __root: error ? 'Une erreur est survenue lors de la soumission du formulaire.' : undefined,
    ...(error ? errorsByPath(error.graphQLErrors as AppGraphQLError[]) : {}),
  }), [error]);

  async function submit(payload: ExchangeData) {
    mutate({
      variables: {
        payload:{
          ...payload,
          date: payload.date?.toISOString(),
        },
      },
    }).then((response) => {
      navigate(route(UpdateExchange, { uid: response.data!.Exchange.create.uid }), {
        state: {
          exchangeCreated: true,
          previousUrl,
        },
      });
    }).catch(onMutateError);
  }

  return <div className="w-full">

    <ExchangeForm onSubmit={submit} errors={mappedErrors} />
  </div>;
}, ROUTE_CREATE_EXCHANGE);
