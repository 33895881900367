export const ROUTE_LIST_USERS = '/member/list';
export const ROUTE_UPDATE_USER = '/members/:uid/update';
export const ROUTE_LIST_SERVICES = '/service/list';
export const ROUTE_UPDATE_SERVICE = '/services/:uid/update';
export const ROUTE_CREATE_SERVICE = '/services/create';
export const ROUTE_LIST_CATEGORIES = '/categories/list';
export const ROUTE_CREATE_CATEGORY = '/category/create';
export const ROUTE_UPDATE_CATEGORY = '/category/:uid/create';
export const ROUTE_LIST_EXCHANGES = '/exchanges/list';
export const ROUTE_UPDATE_EXCHANGE = '/exchanges/:uid/update';
export const ROUTE_CREATE_EXCHANGE = '/exchange/create';

