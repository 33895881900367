import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { useMemo } from 'react';
import { declareAdminRoute } from '@app/router/router';
import { useMutation } from '@app/api/apollo/useMutation';
import CreateUserMutation from '@graphql/mutation/user/CreateUser.graphql';
import { useNavigate } from 'react-router-dom';
import { onMutateError } from '@graphql/utils';
import { errorsByPath } from '@app/api/errors';
import { AppGraphQLError } from '@app/api/errors/GraphQLErrorCodes';
import { onCreatedUser } from '@graphql/store/users';
import { route } from '@app/router/generator';
import MemberForm, { UserData } from '@app/components/Form/Admin/Member/MemberForm';
import UpdateMember from '@app/pages/Admin/Member/UpdateMember/UpdateMember';
import usePreviousUrlLocationState from '@app/hooks/usePreviousUrlLocationState';

interface MutationResponse {
  User: {
    create: { uid: string }
  }
}

export default declareAdminRoute(function CreateMember() {
  useDocumentTitle('Ajout d\'un utilisateur');
  const { previousUrl } = usePreviousUrlLocationState();

  const navigate = useNavigate();
  const [mutate, { error }] = useMutation<MutationResponse>(CreateUserMutation, {
    update(cache, { data }) {
      onCreatedUser(cache, data!.User.create);
    },
  });

  const mappedErrors = useMemo(() => ({
    __root: error ? 'Une erreur est survenue lors de la soumission du formulaire.' : undefined,
    ...(error ? errorsByPath(error.graphQLErrors as AppGraphQLError[]) : {}),
  }), [error]);

  async function submit(payload: UserData) {
    mutate({
      variables: { payload },
    }).then((response) => {
      navigate(route(UpdateMember, { uid: response.data!.User.create.uid }), {
        state: {
          userCreated: true,
          previousUrl,
        },
      });
    }).catch(onMutateError);
  }

  return <div className="w-full">

    <MemberForm onSubmit={submit} errors={mappedErrors} />
  </div>;
}, '/users/create');
