import { PropsWithChildren } from 'react';
import tw from 'twin.macro';
import styled from '@emotion/styled';

const variants = {
  default: tw`
    text-black
  `,
  active: tw`
    text-green-500
  `,
  inactive: tw`
    text-red-600
  `,
};

interface StyledDotProps {
  variant?: keyof typeof variants
}

/**
 * Uses {@link https://dev.to/maissenayed/conditional-react-props-with-typescript-43lg conditional React props}
 * with TypeScript using
 */
type Props = PropsWithChildren & StyledDotProps;

export const StyledDot = styled.p<StyledDotProps>(({
  variant = 'default',
}) => [
  // variants
  variants[variant],
]);

export default function Dot(
  props: Props,
){

  const {
    variant,
    children,
    ...remainingProps
  } = props;

  return <StyledDot
    variant={variant}
    {...remainingProps}
  >
    &#9679;
    {children}
  </StyledDot>;
}
