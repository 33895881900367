import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

export interface Props{
  id: string
  label: string
  options: Options[]
  selectedValues: string[]
  onSelect: (selected: string[]) => void
}

export interface Options {
  value : string
  label: string
}

export default function Autocomplete({
  id,
  label,
  options,
  selectedValues,
  onSelect,
}: Props){
  const [searchedTerm, setSearchedTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const filteredOptions = useMemo(() => {
    return options.filter(option =>
      option.label.toLowerCase().includes(searchedTerm.toLowerCase()),
    );
  }, [searchedTerm, options]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedTerm(event.target.value);
    setIsOpen(true);
  };

  const handleInputClick = () => {
    setIsOpen(true);
  };

  const handleOptionClick = (option: Options) => {
    if (selectedValues.includes(option.value)) {
      const updatedSelectedValues = selectedValues.filter(
        selectedOption => selectedOption !== option.value,
      );
      onSelect(updatedSelectedValues);
      return;
    }

    onSelect([...selectedValues, option.value]);
    setSearchedTerm('');
    setIsOpen(false);
  };

  const labelsPerValue: Record<string,string> = useMemo(() => Object.fromEntries(
    options.reduce(
      (carry, option) => carry.set(option.value, option.label),
      new Map<string, string>(),
    ),
  ), [options]);

  return (
    <div ref={containerRef} className="relative w-full">
      <label className="block mb-1" htmlFor={id} >{label}</label>
      <div className="mb-2 relative">
        <div className="flex flex-wrap">
          {selectedValues.map((value) => {
            const label = labelsPerValue[value];
            return <span
              key={value}
              onClick={() => onSelect(selectedValues.filter(v => v !== value))}
              className="bg-primary text-white text-sm px-2 py-1 rounded-xl mr-2 mb-2 cursor-pointer"
            >
              {label} &times;
            </span>;
          })}
        </div>
        <input
          id={id}
          type="text"
          value={searchedTerm}
          onChange={handleInputChange}
          onClick={handleInputClick}
          placeholder="Sélectionnez..."
          className="border rounded px-2 py-1 w-full mt-0.5"
        />
      </div>
      {isOpen && (
        <ul className="border absolute z-50 bg-white right-0 left-0 border-gray-300 max-h-40 overflow-y-auto">
          {filteredOptions.length === 0 ? (
            <li className="px-2 py-1 text-gray-500">Aucun résultat trouvé.</li>
          ) : (
            filteredOptions.map(option => (
              <li
                key={option.value}
                onClick={() => handleOptionClick(option)}
                className={clsx('cursor-pointer hover:bg-gray-100 px-2 py-1', {
                  'bg-primary text-white hover:bg-primary': selectedValues.includes(option.label),
                })}
              >
                {option.label}
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
}
