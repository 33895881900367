import { PropsWithChildren } from 'react';
import utpaLogo from '@images/logo.png';

/**
 * Blank layout for the most simple pages (login, logout, transitions, …)
 */
export default function BlankLayout({ children }: PropsWithChildren) {

  return <>
    <Header />

    <main className="flex justify-center">
      {children}
    </main>
  </>;
}

function Header() {
  return <header className="flex justify-center px-0">
    <img src={utpaLogo} alt="UTPA LOGO" className="w-[300px]" />
  </header>;
}
