import tw from 'twin.macro';
import { FormEvent, useState } from 'react';
import { FormRootErrors } from '@app/components/UI/Form/FormRootErrors';
import TextInput from '@app/components/UI/Form/TextInput';
import ToggleInput from '@app/components/UI/Form/ToggleInput';
import Button from '@app/components/UI/Button';
import { FormErrorsMap } from '@app/components/UI/Form';
import { route } from '@app/router/generator';
import ListMembers from '@app/pages/Admin/Member/ListMembers/ListMembers';
import usePreviousUrlLocationState from '@app/hooks/usePreviousUrlLocationState';
import TextArea from '@app/components/UI/Form/TextArea';
import Select from '@app/components/UI/Form/Select';
import MultiselectService from '@app/components/Form/Member/Profile/MultiselectService';
import SelectYear from '@app/components/UI/Form/Select/SelectYear';

export interface UserData {
  civility?: string | null
  email?: string | null
  password?: string | null
  firstname?: string | null
  lastname?: string | null
  birthYear?: number | null
  address?: string | null
  city?: string | null
  postCode?: string | null
  phone?: string | null
  mobile?: string | null
  comment:string | null
  details: string | null
  providedServices: string[]
  searchedServices: string[]
  startingCapital: number
  paidMembershipDate: Date | null
  providedInsuranceCertificateDate: Date | null
  signedCharterDate: Date | null
  active: boolean
  admin: boolean
}

interface UserInformativeData {
  uid: string
  createdAt: Date | null
  updatedAt: Date | null
}

interface Props {
  onSubmit: (payload: UserData) => void
  errors?: FormErrorsMap<keyof UserData>
}

interface CreateProps extends Props {
  initialData?: never
}

interface UpdateProps extends Props {
  initialData: UserData
}

interface InformativeProps extends Props {
  informativeProps?: UserInformativeData
}

export default function MemberForm({
  onSubmit,
  initialData = undefined,
  informativeProps,
  errors = {},
}: ( CreateProps | UpdateProps ) & InformativeProps) {
  const FormActions = tw.div`flex items-center justify-end mt-10`;

  const isUpdate = initialData !== undefined;
  const { previousUrl } =  usePreviousUrlLocationState();

  const [civility, setCivility] = useState<string | null>(initialData?.civility ?? null);
  const [email, setEmail] = useState<string | null>(initialData?.email ?? null);
  const [password, setPassword] = useState<string | null>(null);
  const [firstname, setFirstname] = useState<string | null>(initialData?.firstname ?? null);
  const [lastname, setLastname] = useState<string | null>(initialData?.lastname ?? null);
  const [birthYear, setBirthYear] = useState<number | null>(initialData?.birthYear ?? null);
  const [address, setAddress] = useState<string | null>(initialData?.address ?? null);
  const [city, setCity] = useState<string | null>(initialData?.city ?? null);
  const [postCode, setPostCode] = useState<string | null>(initialData?.postCode ?? null);
  const [phone, setPhone] = useState<string | null>(initialData?.phone ?? null);
  const [mobile, setMobile] = useState<string | null>(initialData?.mobile ?? null);
  const [details, setDetails] = useState<string | null>(initialData?.details ?? null);
  const [comment, setComment] = useState<string | null>(initialData?.comment ?? null);
  const [providedServices, setProvidedServices] = useState<string[]>(initialData?.providedServices ?? []);
  const [searchedServices, setSearchedServices] = useState<string[]>(initialData?.searchedServices ?? []);
  const [startingCapital, setStartingCapital] = useState<number>(initialData?.startingCapital ?? 0);
  const [paidMembershipDate, setPaidMembershipDate] = useState<Date|null>(initialData?.paidMembershipDate ? new Date(initialData.paidMembershipDate) : null);
  const [providedInsuranceCertificateDate, setProvidedInsuranceCertificateDate] = useState<Date|null>(initialData?.providedInsuranceCertificateDate ? new Date(initialData.providedInsuranceCertificateDate) : null);
  const [signedCharterDate, setSignedCharterDate] = useState<Date|null>(initialData?.signedCharterDate ? new Date(initialData.signedCharterDate) : null);
  const [active, setActive] = useState<boolean>(initialData?.active ?? true);
  const [admin, setAdmin] = useState<boolean>(initialData?.admin ?? false);

  function submit(event: FormEvent<HTMLFormElement>): void {
    // Prevent default to avoid page reload.
    event.preventDefault();

    onSubmit({
      civility,
      email,
      password,
      firstname,
      lastname,
      birthYear,
      address,
      city,
      postCode,
      phone,
      mobile,
      details,
      comment,
      providedServices,
      searchedServices,
      startingCapital,
      paidMembershipDate,
      providedInsuranceCertificateDate,
      signedCharterDate,
      active,
      admin,
    });
  }

  return <form className="mb-10 max-w-screen-lg" onSubmit={submit}>

    <Button href={previousUrl ?? route(ListMembers)}> Retour à la liste</Button>

    <FormRootErrors errors={errors?.__root} />

    {informativeProps &&
      <FormContainer>
        <legend className="font-bold">Admin</legend>

        <FormRow>
          <TextInput<string>
            id="uid"
            label="Identifiant"
            value={informativeProps.uid}
            disabled
          />
          <TextInput<Date>
            type="date"
            id="createdAt"
            label="Créé le :"
            value={informativeProps.createdAt}
            disabled
          />
          <TextInput<Date>
            type="date"
            id="updatedAt"
            label="Modifié le :"
            value={informativeProps.updatedAt}
            disabled
          />
        </FormRow>
      </FormContainer>
    }

    <FormContainer className="mt-5">
      <legend className="font-bold">Identifiants</legend>
      <FormRow>
        <Select<string>
          id="civility"
          label="Civilité"
          options={[
            { label: 'Monsieur', value: 'Mr' },
            { label: 'Madame', value: 'Mme' },
          ]}
          value={civility}
          onChangedValue={setCivility}
          required
          errors={errors?.civility}
        />
        <TextInput<string>
          id="email"
          label="Email"
          type="email"
          value={email}
          onChangedValue={setEmail}
          required
          InputProps={{
            autoFocus: true,
            autoComplete: 'email',
          }}
          errors={errors?.email}
        />
        <TextInput<string>
          id="password"
          label="Mot de passe"
          type="password"
          value={password}
          onChangedValue={setPassword}
          InputProps={{
            placeholder: isUpdate
              ? 'Laisser vide pour conserver le mot de passe actuel'
              : 'Laisser vide pour générer un mot de passe aléatoire'
            ,
          }}
          errors={errors?.password}
        />
      </FormRow>
    </FormContainer>

    <FormContainer>
      <legend className="font-bold">Données personnelles</legend>
      <FormRow>
        <TextInput<string>
          id="firstname"
          label="Prénom"
          value={firstname}
          onChangedValue={setFirstname}
          required
          errors={errors?.firstname}
        />
        <TextInput<string>
          id="lastname"
          label="Nom"
          value={lastname}
          onChangedValue={setLastname}
          required
          errors={errors?.lastname}
        />
        <SelectYear
          id="birthYear"
          label="Année de naissance"
          value={birthYear}
          onChangedValue={setBirthYear}
          required
          errors={errors?.birthYear}
          minYear={1900}
          maxYear={new Date().getFullYear()}
        />
      </FormRow>
      <FormRow>
        <TextInput<string>
          id="address"
          label="Adresse"
          value={address}
          onChangedValue={setAddress}
          required
          errors={errors?.address}
        />
        <TextInput<string>
          id="city"
          label="Ville"
          value={city}
          onChangedValue={setCity}
          required
          errors={errors?.city}
        />
        <TextInput<string>
          id="codePost"
          label="Code postal"
          value={postCode}
          onChangedValue={setPostCode}
          required
          errors={errors?.postCode}
        />
      </FormRow>
    </FormContainer>

    <FormContainer>
      <legend className="font-bold">Téléphonie</legend>
      <FormRow>
        <TextInput<string>
          id="phone"
          label="Téléphone fixe"
          value={phone}
          onChangedValue={setPhone}
          errors={errors?.phone}
        />
        <TextInput<string>
          id="mobile"
          label="Téléphone mobile"
          value={mobile}
          onChangedValue={setMobile}
          errors={errors?.mobile}
        />
      </FormRow>
    </FormContainer>

    <FormContainer>
      <legend className="font-bold">Détails utilisateur</legend>
      <TextArea
        id="details"
        label="Détails"
        hint="Précisez les jours ou les horaires où vous êtes le plus suceptible de pouvoir échanger des services"
        placeholder="Exemple : le weekend, la semaine entre 17h et 19h"
        value={details}
        onChangedValue={setDetails}
        errors={errors?.details}
      />
      <TextArea
        id="comment"
        label="Commentaire"
        hint="Notez ici toute information utile pour vos futurs échanges de services. Il n'est pas utile de répéter vos coordonnées à cet endroit."
        placeholder="Exemple : merci de me contacter de préférence par téléphone"
        value={comment}
        onChangedValue={setComment}
        errors={errors?.comment}
      />
    </FormContainer>

    <FormContainer>
      <legend className="font-bold">Services</legend>
      <div className="mb-10 max-w-screen-lg flex flex-col md:flex-row gap-4 md:items-end">
        <MultiselectService
          id="providedServices"
          label="Services que je propose"
          selectedValues={providedServices}
          onSelect={setProvidedServices}
        />

        <MultiselectService
          id="searchServices"
          label="Services que je recherche"
          selectedValues={searchedServices}
          onSelect={setSearchedServices}
        />
      </div>
      <FormContainer>
        <legend className="font-bold">Avoir de départ</legend>

        <TextInput<number>
          id="startingCapital"
          label="Heures"
          type="number"
          InputProps={{
            min: 0,
          }}
          value={startingCapital}
          onChangedValue={(value) => setStartingCapital(value ?? 0)}
        />
      </FormContainer>
    </FormContainer>

    <FormContainer>
      <legend className="font-bold">Association</legend>

      <FormRow>
        <TextInput<Date>
          type="date"
          id="paidMembershipDate"
          label="Adhésion payée le :"
          value={paidMembershipDate}
          onChangedValue={setPaidMembershipDate}
          InputProps={{ max: new Date().toISOString().split('T')[0] }}
        />
        <TextInput<Date>
          type="date"
          id="providedInsuranceCertificateDate"
          label="Attestation d'assurance fournie le :"
          value={providedInsuranceCertificateDate}
          onChangedValue={setProvidedInsuranceCertificateDate}
          InputProps={{ max: new Date().toISOString().split('T')[0] }}
        />
        <TextInput<Date>
          type="date"
          id="signedCharterDate"
          label="Charte signée le :"
          value={signedCharterDate}
          onChangedValue={setSignedCharterDate}
          InputProps={{ max: new Date().toISOString().split('T')[0] }}
        />
      </FormRow>
    </FormContainer>

    <fieldset className="mb-1">
      <legend className="font-bold">Droits</legend>

      <ToggleInput id="admin" label="Droits administrateur" checked={admin} onChangedValue={setAdmin} />
      <ToggleInput id="active" label="Est actif" checked={active} onChangedValue={setActive} />

    </fieldset>

    <FormActions>
      <Button type="submit" variant="primary">
        {isUpdate ? 'Modifier' : 'Créer'}
      </Button>
    </FormActions>
  </form>;
}

const FormContainer = tw.div`
  w-full
  max-w-[920px]
  mb-1
`;

const FormRow = tw.div`
  flex
  flex-col
  gap-3
  md:flex-row
`;
