import Table, { TableActionsButton } from '@app/components/UI/Table';
import Pagination from '@app/components/UI/Pagination';
import { toChunks } from '@app/utils/array';
import ListCategories, { Category } from '@app/pages/Admin/Category/ListCategories/ListCategories';
import { route } from '@app/router/generator';
import DropMenu from '@app/components/UI/DropMenu';
import Link from '@app/components/Router/Link';
import UpdateCategory from '@app/pages/Admin/Category/UpdateCategory/UpdateCategory';
import { WithPreviousUrl } from '@app/hooks/usePreviousUrlLocationState';
import { useSortHook } from '@app/hooks/useSortHook';
import { compareStrings } from '@app/utils/sort';
import Dot from '@app/components/UI/Icon/Dot';
import { useCallback, useMemo } from 'react';

interface Props {
  categories: readonly Category[]
  currentPage: number
  perPage?: number
}

export default function CategoriesTable({
  categories,
  currentPage,
  perPage = 10,
  previousUrl,
}: WithPreviousUrl<Props>) {
  const { sortColumn, sortOrder, handleSort } = useSortHook();

  const sortSettings = {
    'Intitulé': true,
  };

  const getSortedCategories = useCallback(() => {
    return [...categories].sort((a, b) => {
      switch (sortColumn) {
        case 'Intitulé':
          return compareStrings(a.name, b.name, sortOrder);
        default:
          return 0;
      }
    });
  }, [categories, sortColumn, sortOrder]);

  const sortedCategories = useMemo(() => getSortedCategories(), [getSortedCategories]);

  const pages = toChunks(sortedCategories, perPage);
  const pageCategories = pages[currentPage - 1] ?? [];

  return <>
    <Table
      headers={[
        'Intitulé',
        'Actif',
        '', // Actions
      ]}
      onHeaderClick={handleSort}
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      sortSettings={sortSettings}
      rows={pageCategories.map(category => <TableItem
        previousUrl={previousUrl}
        key={category.uid}
        category={category}
      />)}
    />

    <Pagination current={currentPage} pages={Object.keys(pages).map(pageIdx => route(ListCategories, {}, {
      page: String(parseInt(pageIdx) + 1),
    }))} />
  </>;
}

function TableItem({ category, previousUrl }: WithPreviousUrl<{
  category: Category
}> ) {
  return <tr>
    <td>{category.name}</td>
    <td>{category.isActive ? <Dot variant="active"> Oui</Dot> : <Dot variant="inactive"> Non</Dot>} </td>
    <td className="text-right">
      <TableItemMenu uid={category.uid} previousUrl={previousUrl}/>
    </td>
  </tr>;
}

function TableItemMenu({ uid, previousUrl }: WithPreviousUrl<{ uid: string }>) {
  return <DropMenu>
    <DropMenu.Trigger>
      <TableActionsButton />
    </DropMenu.Trigger>
    <DropMenu.Items>
      <DropMenu.Item>
        <Link to={route(UpdateCategory, { uid } )} state={{ previousUrl }}>
          Modifier
        </Link>
      </DropMenu.Item>
    </DropMenu.Items>
  </DropMenu>;
}
