import { Category } from '@app/models/types/Category';
import FindCategoryQuery from '@graphql/query/category/FindCategory.graphql';
import UpdateCategoryMutation from '@graphql/mutation/category/UpdateCategory.graphql';
import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { useMemo } from 'react';
import { declareAdminRoute } from '@app/router/router';
import { useMutation } from '@app/api/apollo/useMutation';
import { useLocation, useParams } from 'react-router-dom';
import { onMutateError } from '@graphql/utils';
import { errorsByPath } from '@app/api/errors';
import { AppGraphQLError } from '@app/api/errors/GraphQLErrorCodes';
import UnexpectedError from '@app/errors/UnexpectedError';
import { trans } from '@app/translations';
import { useQuery } from '@apollo/client';
import { useNotFoundHandler } from '@app/components/ErrorBoundary';
import Alert from '@app/components/UI/Alert';
import { ROUTE_UPDATE_CATEGORY } from '@app/paths';
import CategoryForm, { CategoryData } from '@app/components/Form/Admin/Category/CategoryForm';

interface MutationResponse {
  Category: {
    update: {
      uid: string
    }
  }
}

interface FindCategoryQueryResponse {
  Category: {
    find: Category
  }
}

interface RedirectFromCreationState {
  categoryCreated: boolean
}

const Page = declareAdminRoute(function UpdateCategory() {
  useDocumentTitle('Modifier une catégorie');

  const { uid } = useParams();
  const notFoundHandler = useNotFoundHandler();
  const { categoryCreated = false } = (useLocation().state ?? {}) as RedirectFromCreationState;

  const query = useQuery<FindCategoryQueryResponse>(FindCategoryQuery, {
    variables: { uid },
    context: {
      // On GraphQL Not Found error, show a Not Found page
      onNotFound: notFoundHandler,
    },
  });

  const [mutate, mutationState] = useMutation<MutationResponse>(UpdateCategoryMutation);

  const mappedErrors = useMemo(() => {
    const error = mutationState.error;

    return {
      __root: error ? 'Une erreur est survenue lors de la soumission du formulaire.' : undefined,
      ...(error ? errorsByPath(error.graphQLErrors as AppGraphQLError[]) : {}),
    };
  }, [mutationState.error]);

  async function submit(payload: CategoryData) {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    mutate({
      variables: { uid, payload },
    }).catch(onMutateError);
  }

  if (query.error) {
    throw new UnexpectedError(query.error.message, query.error);
  }

  if (query.loading) {
    // TODO: better loading state, with a dedicated skeleton or generic loader?
    return <p>{trans('common.loading')}</p>;
  }

  const category = query.data!.Category.find;

  return <div className="w-full">
    {categoryCreated && !mutationState.called && <Alert variant="success">
      Catégorie créé avec succès.
    </Alert>}

    {mutationState.called && mutationState.data?.Category && <Alert variant="success">
      Catégorie modifié avec succès.
    </Alert>}

    <CategoryForm onSubmit={submit} errors={mappedErrors} initialData={category} />

  </div>;
}, ROUTE_UPDATE_CATEGORY );

export default Page;
