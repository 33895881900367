import { ComponentProps } from 'react';
import ModalUI from '@app/components/UI/Modal/Modal';
import Tag from '@app/components/UI/Tag';
import Avatar from '@app/components/UI/Avatar/Avatar';
import styled from '@emotion/styled';
import tw from 'twin.macro';
import { User } from '@app/pages/Member/Search/Search';

type Props = {
  user: User
} & Pick<ComponentProps<typeof ModalUI>, 'opened' | 'close'>;

export default function SearchModal({ user, opened, close }: Props) {
  return <ModalUI key={user.uid} opened={opened} close={close}>
    <UserInfoStyled>
      <Avatar />
      <UserInfoContentStyled>
        <UserNameStyled>{user.civility} {user.firstname} {user.lastname}</UserNameStyled>
        <span>{user.postCode} {user.city}</span>
      </UserInfoContentStyled>
    </UserInfoStyled>

    <ContactInfoStyled>
      <UserModalTitleStyled>Coordonnées</UserModalTitleStyled>
      {user.phone && <a href={`tel:${user.phone}`}>{user.phone}</a>}
      {user.mobile && <a href={`tel:${user.mobile}`}>{user.mobile}</a>}
      <a href={`tel:${user.email}`}>{user.email}</a>
    </ContactInfoStyled>

    {/* Todo availability */}
    {/* <ContactInfoStyled>*/}
    {/*  <UserModalTitleStyled>Disponibilités</UserModalTitleStyled>*/}
    {/*  <p>{user.availability}</p>*/}
    {/* </ContactInfoStyled>*/}

    <ServicesSectionStyled>
      <ServicesDetailStyled>
        {user.providedServices.length > 0 && (
          <>
            <UserModalTitleStyled>Je propose</UserModalTitleStyled>
            <ServicesListStyled>
              {user.providedServices.map(service => <li key={service.uid}>
                <Tag title={service.name}></Tag>
              </li>,
              )}
            </ServicesListStyled>
          </>
        )}
        {user.details && (
          <>
            <UserModalTitleStyled>Description des services proposés</UserModalTitleStyled>
            <p>{user.details}</p>
          </>
        )}
      </ServicesDetailStyled>
      {user.searchedServices.length > 0 && (
        <ServicesDetailStyled>
          <UserModalTitleStyled>Je recherche</UserModalTitleStyled>
          <ServicesListStyled>
            {user.searchedServices.map(service => <li key={service.uid}>
              <Tag title={service.name} />
            </li>,
            )}
          </ServicesListStyled>
        </ServicesDetailStyled>
      )}
    </ServicesSectionStyled>

    {user.comment && (
      <>
        <UserModalTitleStyled>Commentaire</UserModalTitleStyled>
        <p className="text-justify">{user.comment}</p>
      </>
    )}
  </ModalUI>;
}

const UserInfoStyled = tw.div`
  flex
  items-center
`;

const UserInfoContentStyled = tw.div`
  ml-2.5
  flex
  flex-col
  text-sm
  uppercase
`;

const UserNameStyled = tw.span`
  text-lg
  font-semibold
`;

const ContactInfoStyled = tw.div`
  flex
  flex-col
`;

const UserModalTitleStyled = tw.span`
  mt-5
  mb-1
  block
  font-semibold
`;

const ServicesSectionStyled = tw.div`
  flex
  flex-col
  lg:flex-row
  lg:gap-5
`;

const ServicesDetailStyled = styled.div`
  ${tw`
    lg:my-0
    lg:flex-1
    text-justify
  `}

  &:nth-of-type(2) {
    ${tw`
      lg:mt-0
      relative
      lg:ps-8
    `}

    &:before {
      ${tw`
      md:block
      hidden
      absolute
      left-0
      top-0
      content-['']
      w-[1px]
      h-full
      bg-gray-300
    `}
    }
  }
`;

const ServicesListStyled = tw.div`
  mt-2
  mx-0
  p-0
  list-none
  flex
  flex-wrap
  gap-1.5
`;
