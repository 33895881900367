import Select from '@app/components/UI/Form/Select';
import { useQuery } from '@apollo/client';
import React from 'react';
import ListMembers from '@graphql/query/exchange/ListMembers.graphql';

interface Member {
  uid: string
  email: string
}

export type Uid = string;

interface Response {
  User: {
    listMemberForExchanges: Member[]
  }
}

function useMembers() {
  const { data, loading, error } = useQuery<Response>(ListMembers);

  return {
    loading,
    error,
    members: data?.User.listMemberForExchanges ?? [],
  };
}

type Props = Omit<React.ComponentProps<typeof Select<string>>, 'options'>;

export default function SelectMember({
  id,
  label,
  disabled,
  value,
  onChangedValue,
  errors,
}: Props) {
  const { members } = useMembers();

  return <Select<string>
    id={id}
    label={label}
    options={members.map(member => ({ label: member.email, value: member.uid }))}
    disabled={disabled}
    value={value}
    onChangedValue={onChangedValue}
    errors={errors}
  />;
}
