import { useState } from 'react';

export type SortOrder = 'asc' | 'desc';

export function useSortHook(initialSortColumn: string | null = null, initialSortOrder: SortOrder = 'asc') {
  const [sortColumn, setSortColumn] = useState<string | null>(initialSortColumn);
  const [sortOrder, setSortOrder] = useState<SortOrder>(initialSortOrder);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      return;
    }

    setSortColumn(column);
    setSortOrder('asc');
  };

  return { sortColumn, sortOrder, handleSort };
}
