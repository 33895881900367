import { useState } from 'react';
import Button from '@app/components/UI/Button';
import { User } from '@app/pages/Member/Search/Search';
import Autocomplete from '@app/components/UI/Form/Autocomplete';
import { Category } from '@app/models/types/Category';

export interface Filters {
  selectedUsers?: string[] | null
  selectedCategories?: string[] | null
}
interface Props {
  users: readonly User[]
  categories: Category[]
  onSubmit: (filters: Filters) => void
  initialFilters?: Filters
}

export default function SearchFilters({
  users,
  categories,
  onSubmit,
  initialFilters,
}: Props) {
  const [selectedUsers, setSelectedUsers] = useState<string[]>(initialFilters?.selectedUsers || []);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(initialFilters?.selectedCategories || []);

  function submit(e) {
    e.preventDefault();
    e.currentTarget.blur();
    onSubmit({ selectedUsers, selectedCategories });
  }

  return <form className="mb-10 max-w-screen-lg flex flex-col md:flex-row gap-4 md:items-end">
    <Autocomplete
      id="selectedUsers"
      label="Rechercher par personne"
      options={users.map(user => ({
        value: user.uid,
        label: user.firstname + ' ' + user.lastname,
      }))}
      selectedValues={selectedUsers}
      onSelect={setSelectedUsers}
    />

    <Autocomplete
      id="selectedCategories"
      label="Rechercher par secteur de service"
      options={categories.map(category => ({
        value: category.uid,
        label: category.name,
      }))}
      selectedValues={selectedCategories}
      onSelect={setSelectedCategories}
    />
    <div className="mb-2">
      <Button variant="primary" type="submit" onClick={submit}>
        Rechercher
      </Button>
    </div>
  </form>;
}
