import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { declareMemberRoute } from '@app/router/router';
import ContentHeader from '@app/components/UI/ContentHeader';
import Button from '@app/components/UI/Button';
import ExchangesTable from '@app/pages/User/Exchange/ListExchanges/ExchangesTable';
import Layout from '@app/components/UI/Layout/Layout';
import CardInfo from '@app/components/UI/Card/CardInfo';
import styled from '@emotion/styled';
import tw from 'twin.macro';
import Icon from '@app/components/UI/Icon/Icon';
import Collapsible from '@app/components/UI/Collapsible';
import ExchangesFilters, { filterExchanges, Filters } from '@app/pages/User/Exchange/ListExchanges/ExchangesFilters';
import { useAuthContext } from '@app/hooks/useAuthContext';
import { useQuery } from '@apollo/client';
import ListMyExchangesQuery from '@graphql/query/exchange/ListMyExchanges.graphql';
import { trans } from '@app/translations';
import { Exchange } from '@app/models/types/Exchange';
import { groupBy } from '@app/utils/array';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { route } from '@app/router/generator';
import { usePageNumberFromQuery } from '@app/hooks/usePageNumber';
import { renderDuration } from '@app/utils/duration';
import { useNotFoundHandler } from '@app/components/ErrorBoundary';
import MyCapital from '@graphql/query/user/MyCapital.graphql';

interface ListMyExchangesResponse {
  Exchange: {
    listMyExchanges: Exchange[]
  }
}
interface User {
  startingCapital: number | null
}

interface MyCapitalQueryResponse {
  User: {
    me: User
  }
}

const Page = declareMemberRoute(function ListMyExchanges() {
  useDocumentTitle('Exchanges');

  const { profile } = useAuthContext();
  const notFoundHandler = useNotFoundHandler();
  const uid = profile?.uid;

  const query = useQuery<MyCapitalQueryResponse>(MyCapital, {
    variables: { uid },
    context: {
      // On GraphQL Not Found error, show a Not Found page
      onNotFound: notFoundHandler,
    },
  });
  const startingCapital = query.data!.User.me.startingCapital;

  const navigate = useNavigate();
  const currentPage = usePageNumberFromQuery();
  // cache-and-network to reload all exchanges in case an admin has added one in the meantime.
  const { data, loading, error } = useQuery<ListMyExchangesResponse>(ListMyExchangesQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState<Filters>(unserializeFilters(searchParams));

  function unserializeFilters(searchParams: URLSearchParams): Filters {
    const filters: Filters = {};
    for (const [key, value] of searchParams.entries()) {
      if (['startDate', 'endDate'].includes(key)) {
        filters[key] = new Date(value);
        continue;
      }
      filters[key] = value;
    }
    return filters;
  }

  function onSubmitFilters(filters: Filters) {
    setFilters(filters);
    const serializedFilters = Object.fromEntries(Object.entries(filters).map(([key, filter]) => {
      if (filter instanceof Date) {
        return [key, filter.toISOString()];
      }
      return [key, filter];
    }).filter(([, filter]) => filter !== null));

    // Reset to first page:
    navigate(route(Page, { page: '1' }, serializedFilters));
  }

  if (loading) {
    // TODO: better loading state, with a dedicated skeleton or generic loader?
    return <Layout>
      <p>{trans('common.loading')}</p>
    </Layout>;
  }

  if (error) {
    // Rethrow to let the error boundary handle it and show a generic error page
    throw error;
  }

  const exchanges = data!.Exchange.listMyExchanges;
  const filteredExchanges = filterExchanges(exchanges, filters, profile);

  const { issuers = [], providers = [] } = groupBy(exchanges, (exchange) => {
    return exchange.issuer.uid === profile?.uid ? 'issuers': 'providers';
  });
  const issuedDuration = issuers?.reduce((carry, issuer) => carry + issuer.duration, 0);
  const providedDuration = providers?.reduce((carry, provider) => carry + provider.duration, 0);

  const contributedDuration = Math.max(0, ( providedDuration + ( startingCapital ?? 0) * 60) - issuedDuration);

  const exchangeInfo = [
    {
      number: renderDuration(providedDuration),
      label: 'Heures données',
    },
    {
      number: renderDuration(issuedDuration),
      label: 'Heures consommées',
    },
    {
      number: renderDuration(contributedDuration),
      label: 'Heures disponibles',
    },
  ];

  return <Layout>
    <Collapsible initialOpened={false}>
      {({ opened, toggle }) => {
        return <>
          <ContentHeader>
            <ContentHeader.Title>
              <h1>Mes échanges</h1>
              <p className="mb-5">Retrouvez facilement vos échanges avec les membres de la plateforme.</p>
            </ContentHeader.Title>

            <ContentHeader.Actions>
              <Collapsible.Trigger toggle={toggle} opened={opened}>
                <Button variant="neutral">
                  <Icon name="filter" />&nbsp;{opened ? 'Cacher les filtres' : 'Modifier les filtres'}
                </Button>
              </Collapsible.Trigger>
            </ContentHeader.Actions>
          </ContentHeader>
          <CardContainer>
            {exchangeInfo.map((info, index) => (
              <CardInfo key={index} label={info.label} number={info.number} />
            ))}
          </CardContainer>
          <Collapsible.Pane opened={opened}>
            <ExchangesFilters
              initialFilters={filters}
              exchanges={filteredExchanges}
              onSubmit={onSubmitFilters}
            />
          </Collapsible.Pane>
          <ExchangesTable
            profile={profile}
            exchanges={filteredExchanges}
            currentPage={currentPage}
          />
        </>;
      }}
    </Collapsible>
    <p className="mt-5">Vous constatez une erreur sur un échange ? Remontez-le par mail à <a href={`mailto:${AppConfig.APP_CONTACT_EMAIL}`}>{AppConfig.APP_CONTACT_EMAIL}</a></p>
  </Layout>;
}, '/exchange/list');

const CardContainer = styled.div`
  ${tw`
    flex-col
    flex
    md:flex-row
    gap-5
    mb-10
    `}
`;

export default Page;
