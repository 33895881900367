import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { useMemo } from 'react';
import { declareAdminRoute } from '@app/router/router';
import { useMutation } from '@app/api/apollo/useMutation';
import CreateCategoryMutation from '@graphql/mutation/category/CreateCategory.graphql';
import { useNavigate } from 'react-router-dom';
import { onMutateError } from '@graphql/utils';
import { errorsByPath } from '@app/api/errors';
import { AppGraphQLError } from '@app/api/errors/GraphQLErrorCodes';
import { onCreateCategory } from '@graphql/store/categories';
import { route } from '@app/router/generator';
import usePreviousUrlLocationState from '@app/hooks/usePreviousUrlLocationState';
import UpdateCategory from '@app/pages/Admin/Category/UpdateCategory/UpdateCategory';
import { ROUTE_CREATE_CATEGORY } from '@app/paths';
import CategoryForm, { CategoryData } from '@app/components/Form/Admin/Category/CategoryForm';

interface MutationResponse {
  Category: {
    create: { uid: string }
  }
}


const CreateCategoryPage =  declareAdminRoute(function CreateCategory() {
  useDocumentTitle('Ajout d\'une catégorie');

  const { previousUrl } = usePreviousUrlLocationState();

  const navigate = useNavigate();
  const [mutate, { error }] = useMutation<MutationResponse>(CreateCategoryMutation, {
    update(cache, { data }) {
      onCreateCategory(cache, data!.Category.create);
    },
  });

  const mappedErrors = useMemo(() => ({
    __root: error ? 'Une erreur est survenue lors de la soumission du formulaire.' : undefined,
    ...(error ? errorsByPath(error.graphQLErrors as AppGraphQLError[]) : {}),
  }), [error]);

  async function submit(payload: CategoryData) {
    mutate({
      variables: { payload },
    }).then((response) => {
      navigate(route(UpdateCategory, { uid: response.data!.Category.create.uid }), {
        state: {
          categoryCreated: true,
          previousUrl,
        },
      });
    }).catch(onMutateError);
  }

  return <div className="w-full">

    <CategoryForm onSubmit={submit} errors={mappedErrors}/>
  </div>;
}, ROUTE_CREATE_CATEGORY);

export default CreateCategoryPage;
