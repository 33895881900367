import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { useMemo } from 'react';
import { declareAdminRoute } from '@app/router/router';
import { useMutation } from '@app/api/apollo/useMutation';
import UpdateServiceMutation from '@graphql/mutation/service/UpdateService.graphql';
import FindServiceQuery from '@graphql/query/service/FindService.graphql';
import { useLocation, useParams } from 'react-router-dom';
import { onMutateError } from '@graphql/utils';
import { errorsByPath } from '@app/api/errors';
import { AppGraphQLError } from '@app/api/errors/GraphQLErrorCodes';
import UnexpectedError from '@app/errors/UnexpectedError';
import { trans } from '@app/translations';
import { useQuery } from '@apollo/client';
import { useNotFoundHandler } from '@app/components/ErrorBoundary';
import ServiceForm, { ServiceData } from '@app/components/Form/Admin/Service/ServiceForm';
import Alert from '@app/components/UI/Alert';
import { ROUTE_UPDATE_SERVICE } from '@app/paths';
import { Service } from '@app/models/types/Service';

interface MutationResponse {
  Service: {
    update: {
      uid: string
    }
  }
}

interface FindServiceQueryResponse {
  Service: {
    find: Service
  }
}

interface RedirectFromCreationState {
  serviceCreated: boolean
}

const Page = declareAdminRoute(function UpdateService() {
  useDocumentTitle('Modifier un service');

  const { uid } = useParams();
  const notFoundHandler = useNotFoundHandler();
  const { serviceCreated = false } = (useLocation().state ?? {}) as RedirectFromCreationState;

  const query = useQuery<FindServiceQueryResponse>(FindServiceQuery, {
    variables: { uid },
    context: {
      // On GraphQL Not Found error, show a Not Found page
      onNotFound: notFoundHandler,
    },
  });

  const [mutate, mutationState] = useMutation<MutationResponse>(UpdateServiceMutation);

  const mappedErrors = useMemo(() => {
    const error = mutationState.error;

    return {
      __root: error ? 'Une erreur est survenue lors de la soumission du formulaire.' : undefined,
      ...(error ? errorsByPath(error.graphQLErrors as AppGraphQLError[]) : {}),
    };
  }, [mutationState.error]);

  async function submit(payload: ServiceData) {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    mutate({
      variables: { uid, payload },
    }).catch(onMutateError);
  }

  if (query.error) {
    throw new UnexpectedError(query.error.message, query.error);
  }

  if (query.loading) {
    // TODO: better loading state, with a dedicated skeleton or generic loader?
    return <p>{trans('common.loading')}</p>;
  }

  const service = query.data!.Service.find;

  return <div className="w-full">
    {serviceCreated && !mutationState.called && <Alert variant="success">
      Service créé avec succès.
    </Alert>}

    {mutationState.called && mutationState.data?.Service && <Alert variant="success">
      Service modifié avec succès.
    </Alert>}

    <ServiceForm onSubmit={submit} errors={mappedErrors} initialData={service} />

  </div>;
}, ROUTE_UPDATE_SERVICE );

export default Page;
