import { FormEvent, useState } from 'react';
import { FormRootErrors } from '@app/components/UI/Form/FormRootErrors';
import TextInput from '@app/components/UI/Form/TextInput';
import Button from '@app/components/UI/Button';
import { FormErrorsMap } from '@app/components/UI/Form';
import TextArea from '@app/components/UI/Form/TextArea';
import tw from 'twin.macro';
import MultiselectService from '@app/components/Form/Member/Profile/MultiselectService';
import Select from '@app/components/UI/Form/Select';
import SelectYear from '@app/components/UI/Form/Select/SelectYear';

export interface UserData {
  uid?: string | null
  civility?: string | null
  email?: string | null
  firstname?: string | null
  lastname?: string | null
  birthYear?: number | null
  address?: string | null
  city?: string | null
  postCode?: string | null
  phone?: string | null
  mobile?: string | null
  comment?: string | null
  details?: string | null
  providedServices: string[]
  searchedServices: string[]
}

interface Props {
  onSubmit: (payload: UserData) => void
  errors?: FormErrorsMap<keyof UserData>
}


interface UpdateProps extends Props {
  initialData: UserData
}

export default function ProfileForm({
  onSubmit,
  initialData,
  errors = {},
}: UpdateProps) {
  const minYear = 1900;

  const [email, setEmail] = useState<string | null>(initialData?.email ?? null);
  const [ civility, setCivility ] = useState<string | null>(initialData?.civility ?? null);
  const [firstname, setFirstname] = useState<string | null>(initialData?.firstname ?? null);
  const [lastname, setLastname] = useState<string | null>(initialData?.lastname ?? null);
  const [birthYear, setBirthYear] = useState<number | null>(initialData?.birthYear ?? null);
  const [address, setAddress] = useState<string | null>(initialData?.address ?? null);
  const [city, setCity] = useState<string | null>(initialData?.city ?? null);
  const [postCode, setPostCode] = useState<string | null>(initialData?.postCode ?? null);
  const [phone, setPhone] = useState<string | null>(initialData?.phone ?? null);
  const [mobile, setMobile] = useState<string | null>(initialData?.mobile ?? null);
  const [details, setDetails] = useState<string | null>(initialData?.details ?? null);
  const [comment, setComment] = useState<string | null>(initialData?.comment ?? null);
  const [providedServices, setProvidedServices] = useState<string[]>(initialData?.providedServices ?? []);
  const [searchedServices, setSearchedServices] = useState<string[]>(initialData?.searchedServices ?? []);

  function submit(event: FormEvent<HTMLFormElement>): void {
    // Prevent default to avoid page reload.
    event.preventDefault();

    onSubmit({
      email,
      civility,
      firstname,
      lastname,
      birthYear,
      address,
      city,
      postCode,
      phone,
      mobile,
      details,
      comment,
      providedServices,
      searchedServices,
    });
  }

  return <form className="mb-10 max-w-screen-lg" onSubmit={submit}>

    <h1 className="text-3xl">Modifier mes informations</h1>

    <FormRootErrors errors={errors?.__root} />
    <FormContainer>
      <FormRow>
        <TextInput<string>
          id="email"
          label="Email"
          value={email}
          onChangedValue={setEmail}
          required
          errors={errors?.email}
        />
      </FormRow>
    </FormContainer>
    <FormContainer>
      <FormRow>
        <Select<string>
          id="civility"
          label="Civilité"
          options={[
            { label: 'Monsieur', value: 'Mr' },
            { label: 'Madame', value: 'Mme' },
          ]}
          value={civility}
          onChangedValue={setCivility}
          required
          errors={errors?.civility}
        />
        <TextInput<string>
          id="firstName"
          label="Prénom"
          value={firstname}
          onChangedValue={setFirstname}
          required
          errors={errors?.firstname}
        />
        <TextInput<string>
          id="lastname"
          label="Nom"
          value={lastname}
          onChangedValue={setLastname}
          required
          errors={errors?.lastname}
        />
        <SelectYear
          id="birthYear"
          label="Année de naissance"
          value={birthYear}
          onChangedValue={setBirthYear}
          required
          errors={errors?.birthYear}
          minYear={minYear}
          maxYear={new Date().getFullYear()}
        />

      </FormRow>
      <FormRow>
        <TextInput<string>
          id="address"
          label="Adresse"
          value={address}
          onChangedValue={setAddress}
          required
          errors={errors?.address}
        />
        <TextInput<string>
          id="city"
          label="Ville"
          value={city}
          onChangedValue={setCity}
          required
          errors={errors?.city}
        />
        <TextInput<string>
          id="codePost"
          label="Code postal"
          value={postCode}
          onChangedValue={setPostCode}
          required
          errors={errors?.postCode}
        />
      </FormRow>
      <FormRow>
        <TextInput<string>
          id="phone"
          label="Numéro de téléphone"
          value={phone}
          onChangedValue={setPhone}
          errors={errors?.phone}
        />
        <TextInput<string>
          id="mobile"
          label="Téléphone mobile"
          value={mobile}
          onChangedValue={setMobile}
          errors={errors?.mobile}
        />
      </FormRow>
      <FormRow>
        <TextArea
          id="details"
          label="Détails"
          hint="Précisez les jours ou les horaires où vous êtes le plus suceptible de pouvoir échanger des services"
          placeholder="Exemple : le weekend, la semaine entre 17h et 19h"
          value={details}
          onChangedValue={setDetails}
          errors={errors?.details}
        />
      </FormRow>
      <form className="mb-10 max-w-screen-lg flex flex-col md:flex-row gap-4 md:items-end">
        <MultiselectService
          id="providedServices"
          label="Services que je propose"
          selectedValues={providedServices}
          onSelect={setProvidedServices}
        />

        <MultiselectService
          id="searchServices"
          label="Services que je recherche"
          selectedValues={searchedServices}
          onSelect={setSearchedServices}
        />
      </form>
      <FormRow>
        <TextArea
          id="comment"
          label="Commentaire"
          hint="Notez ici toute information utile pour vos futurs échanges de services. Il n'est pas utile de répéter vos coordonnées à cet endroit."
          placeholder="Exemple : merci de me contacter de préférence par téléphone"
          value={comment}
          onChangedValue={setComment}
          errors={errors?.comment}
        />
      </FormRow>
      <FormAction>
        <Button type="submit" variant="primary">Modifier</Button>
      </FormAction>
    </FormContainer>
  </form>;
}

const FormContainer = tw.div`
  w-full
  max-w-[920px]
`;

const FormRow = tw.div`
  flex
  flex-col
  gap-3
  md:flex-row
`;

const FormAction = tw.div`text-right`;
