import MemberProfileCard from '@app/pages/Member/Search/MemberProfileCard';
import Pagination from '@app/components/UI/Pagination';
import { route } from '@app/router/generator';
import tw from 'twin.macro';
import SearchPage from '@app/pages/Member/Search/Search';
import styled from '@emotion/styled';

export default function SearchList({ pages, currentPage, pageUsers }) {
  return (
    <>
      <ServicesList>
        {pageUsers.map((user) => <MemberProfileCard user={user} key={user.uid}/>)}
      </ServicesList>
      <Pagination current={currentPage} pages={Object.keys(pages).map(pageIdx => route(SearchPage, {}, {
        page: String(parseInt(pageIdx) + 1),
      }))} />
    </>
  );
}

const ServicesList = styled.div`
  ${tw`
    w-full
    flex
    flex-wrap
    lg:-ms-[10px]
    mb-5
  `}
`;
