import tw from 'twin.macro';
import { FormEvent, useState } from 'react';
import { FormRootErrors } from '@app/components/UI/Form/FormRootErrors';
import TextInput from '@app/components/UI/Form/TextInput';
import Button from '@app/components/UI/Button';
import { Service } from '@app/models/types/Service';
import { FormErrorsMap } from '@app/components/UI/Form';
import { route } from '@app/router/generator';
import ListServices from '@app/pages/Admin/Service/ListServices/ListServices';
import usePreviousUrlLocationState from '@app/hooks/usePreviousUrlLocationState';
import SelectCategories from '@app/components/Form/Admin/Category/SelectCategory';
import ToggleInput from '@app/components/UI/Form/ToggleInput';

export interface ServiceData {
  name?: string | null
  categoryUid?: string | null
  active: boolean
}

interface Props {
  onSubmit: (payload: ServiceData) => void
  errors?: FormErrorsMap<keyof ServiceData>
}

interface CreateProps extends Props {
  initialData?: never
}

interface UpdateProps extends Props {
  initialData: Service
}

export default function ServiceForm({
  onSubmit,
  initialData = undefined,
  errors = {},
}: CreateProps | UpdateProps) {
  const FormActions = tw.div`flex items-center justify-end mt-10`;

  const isUpdate = initialData !== undefined;
  const { previousUrl } =  usePreviousUrlLocationState();

  const [name, setName] = useState<string | null>(initialData?.name ?? null);
  const [categoryUid, setCategoryUid] = useState<string|null>(null);
  const [active, setActive] = useState<boolean>(initialData?.isActive ?? true);

  function submit(event: FormEvent<HTMLFormElement>): void {
    // Prevent default to avoid page reload.
    event.preventDefault();

    onSubmit({
      name,
      categoryUid,
      active,
    });
  }

  return <form className="mb-10 max-w-screen-lg" onSubmit={submit}>

    <Button href={previousUrl ?? route(ListServices)}> Retour à la liste</Button>

    <FormRootErrors errors={errors?.__root} />

    <fieldset className="mb-1">
      <legend className="font-bold">Nom</legend>

      <TextInput<string>
        id="name"
        label="Intitulé du service"
        type="text"
        value={name}
        onChangedValue={setName}
        required
        InputProps={{
          autoFocus: true,
        }}
        errors={errors?.name}
      />

      <SelectCategories
        id="category"
        label="Category"
        required
        value={categoryUid}
        onChangedValue={setCategoryUid}
        errors={errors?.categoryUid}
      />
    </fieldset>

    <ToggleInput
      id="active"
      label="Est actif"
      checked={active}
      onChangedValue={setActive}
    />

    <FormActions>
      <Button type="submit" variant="primary">
        {isUpdate ? 'Modifier' : 'Créer'}
      </Button>
    </FormActions>
  </form>;
}
