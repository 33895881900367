import tw from 'twin.macro';
import Button from '@app/components/UI/Button';
import { useAuthContext } from '@app/hooks/useAuthContext';
import Link from '@app/components/Router/Link';
import Logout from '@app/pages/Auth/Logout';
import { route } from '@app/router/generator';
import DropMenu from '@app/components/UI/DropMenu';
import Profile from '@app/pages/User/Profile/Profile';
import Icon from '@app/components/UI/Icon/Icon';
import Exchanges from '@app/pages/User/Exchange/ListExchanges/ListMyExchanges';

const PaddedIcon = tw.span`mr-4`;

export default function UserMenu() {
  const { authenticated, profile } = useAuthContext();

  if (!authenticated) {
    return null;
  }

  return <UserMenu.Container>
    <DropMenu>
      <DropMenu.Trigger>
        <Button>
          <Icon name="user" Component={PaddedIcon}/>
          {`${profile.firstname} ${profile.lastname}`}
        </Button>
      </DropMenu.Trigger>

      <DropMenu.Items>
        <DropMenu.Item>
          <Link to={route(Profile)}>
            Mon profil
          </Link>
        </DropMenu.Item>
        <DropMenu.Item>
          <Link to={route(Exchanges)}>
            Mes échanges
          </Link>
        </DropMenu.Item>
        <DropMenu.Item>
          <Link to={route(Logout)}>
            Se déconnecter
          </Link>
        </DropMenu.Item>
      </DropMenu.Items>
    </DropMenu>
  </UserMenu.Container>;
}

UserMenu.Container = tw.div`
  items-start
  hidden
  lg:flex
`;
