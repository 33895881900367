import { TypePolicies } from '@apollo/client';

export enum Types {
  User = 'User',
  Service = 'Service',
  Category = 'Category',
  Exchange = 'Exchange',
}

export default {
  [Types.User]: {
    keyFields: ['uid'],
  },
  [Types.Service]: {
    keyFields: ['uid'],
  },
  [Types.Category]: {
    keyFields: ['uid'],
  },
  [Types.Exchange]: {
    keyFields: ['uid'],
  },
  Query: {
    fields: {
      User: {
        merge: (existing, incoming) => ({ ...existing, ...incoming }),
        fields: {
          me: {
            merge: (existing, incoming) => ({ ...existing, ...incoming }),
          },
          list: {
            merge: (existing, incoming) => incoming,
          },
          listMemberForExchanges: {
            merge: (existing, incoming) => incoming,
          },
        },
      },
      Service: {
        merge: (existing, incoming) => ({ ...existing, ...incoming }),
        fields: {
          list: {
            merge: (existing, incoming) => incoming,
          },
        },
      },
      Category: {
        merge: (existing, incoming) => ({ ...existing, ...incoming }),
        fields: {
          list: {
            merge: (existing, incoming) => incoming,
          },
        },
      },
      Exchange: {
        merge: (existing, incoming) => ({ ...existing, ...incoming }),
        fields: {
          list: {
            merge: (existing, incoming) => incoming,
          },
        },
      },
    },
  },
} as TypePolicies;
