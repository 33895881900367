import Select from '@app/components/UI/Form/Select';
import { useQuery } from '@apollo/client';
import React from 'react';
import ListCategories from '@graphql/query/category/ListCategories.graphql';
import { Category } from '@app/models/types/Category';

export type Uid = string;

type Props = Omit<React.ComponentProps<typeof Select<string>>, 'options'>;

interface Response {
  Category: {
    list: Category[]
  }
}

export function useCategories() {
  const { data, loading, error } = useQuery<Response>(ListCategories);

  return {
    loading,
    error,
    categories: data?.Category.list ?? [],
  };
}

export default function SelectCategories({
  id,
  label,
  disabled,
  value,
  onChangedValue,
  errors,
}: Props) {
  const { categories } = useCategories();

  return <Select<string>
    id={id}
    label={label}
    options={categories.map(category => ({ label: category.name, value: category.uid }))}
    disabled={disabled}
    value={value}
    onChangedValue={onChangedValue}
    errors={errors}
  />;
}
