import { declareAdminRoute } from '@app/router/router';
import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { Exchange } from '@app/models/types/Exchange';
import ExchangeForm, { ExchangeData } from '@app/components/Form/Admin/Exchange/ExchangeForm';
import { ROUTE_UPDATE_EXCHANGE } from '@app/paths';
import { useLocation, useParams } from 'react-router-dom';
import { useNotFoundHandler } from '@app/components/ErrorBoundary';
import { useQuery } from '@apollo/client';
import FindExchangeQuery from '@graphql/query/exchange/FindExchanges.graphql';
import { useMutation } from '@app/api/apollo/useMutation';
import UpdateExchangeMutation from '@graphql/mutation/exchange/UpdateExchange.graphql';
import { useMemo } from 'react';
import { errorsByPath } from '@app/api/errors';
import { AppGraphQLError } from '@app/api/errors/GraphQLErrorCodes';
import { onMutateError } from '@graphql/utils';
import UnexpectedError from '@app/errors/UnexpectedError';
import { trans } from '@app/translations';
import Alert from '@app/components/UI/Alert';

interface MutationResponse {
  Exchange: {
    update: {
      uid: string
    }
  }
}

interface FindExchangeQueryReponse {
  Exchange: {
    find: Exchange
  }
}

interface RedirectFromCreationState {
  exchangeCreated: boolean
}

const Page = declareAdminRoute(function UpdateExchange() {
  useDocumentTitle('Modifier un échange');

  const { uid } = useParams();
  const notFoundHandler = useNotFoundHandler();
  const { exchangeCreated = false } = (useLocation().state ?? {}) as RedirectFromCreationState;

  const query = useQuery<FindExchangeQueryReponse>(FindExchangeQuery, {
    variables: { uid },
    context: {
      // On GraphQL Not Found error, show a Not Found page
      onNotFound: notFoundHandler,
    },
  });

  const [mutate, mutationState] = useMutation<MutationResponse>(UpdateExchangeMutation);

  const mappedErrors = useMemo(() => {
    const error = mutationState.error;

    return {
      __root: error ? 'Une erreur est survenue lors de la soumission du formulaire.' : undefined,
      ...(error ? errorsByPath(error.graphQLErrors as AppGraphQLError[]) : {}),
    };
  }, [mutationState.error]);

  async function submit(payload: ExchangeData) {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    mutate({
      variables: { uid, payload },
    }).catch(onMutateError);
  }

  if (query.error) {
    throw new UnexpectedError(query.error.message, query.error);
  }

  if (query.loading) {
    // TODO: better loading state, with a dedicated skeleton or generic loader?
    return <p>{trans('common.loading')}</p>;
  }

  const exchange = query.data!.Exchange.find;

  return <div className="w-full">
    {exchangeCreated && !mutationState.called && <Alert variant="success">
      Echange créé avec succès.
    </Alert>}

    {mutationState.called && mutationState.data?.Exchange && <Alert variant="success">
      Echange modifié avec succès.
    </Alert>}

    <ExchangeForm onSubmit={submit} errors={mappedErrors} initialData={exchange} />

  </div>;
}, ROUTE_UPDATE_EXCHANGE);

export default Page;
