import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { declareMemberRoute } from '@app/router/router';
import { useQuery } from '@apollo/client';
import ListUsersForMembersQuery from '@graphql/query/user/ListUsersForMembers.graphql';
import Layout from '@app/components/UI/Layout/Layout';
import { trans } from '@app/translations';
import SearchFilters, { Filters } from '@app/pages/Member/Search/SearchFilters';
import ListServicesQuery from '@graphql/query/service/ListServices.graphql';
import { Category } from '@app/models/types/Category';
import { route } from '@app/router/generator';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePageNumberFromQuery } from '@app/hooks/usePageNumber';
import { useState } from 'react';
import { toChunks } from '@app/utils/array';
import SearchList from '@app/pages/Member/Search/SearchList';
import { useCategories } from '@app/components/Form/Admin/Category/SelectCategory';

export interface Service {
  uid: string
  name: string
  category: Category
}

export interface User {
  uid: string
  civility: string
  email: string
  firstname: string
  lastname: string
  city: string
  postCode: string
  phone: string | null
  mobile: string | null
  providedServices: Service[]
  searchedServices: Service[]
  details: string | null
  comment: string | null
}

interface ListUsersForMembersResponse {
  User: {
    listMemberForExchanges: User[]
  }
}

interface ListServicesResponse {
  Service: {
    list: Service[]
  }
}

const Page = declareMemberRoute(function SearchPage() {
  useDocumentTitle('Rechercher un service');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentPage = usePageNumberFromQuery();
  const selectedUsersParam = searchParams.get('selectedUsers');
  const initialFilters: Filters = {
    selectedUsers: selectedUsersParam ? [selectedUsersParam] : null,
    selectedCategories: null,
  };
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const { data: userData, loading: userLoading, error: errorUser } = useQuery<ListUsersForMembersResponse>(ListUsersForMembersQuery);
  const { data: serviceData, loading: serviceLoading, error: errorService } = useQuery<ListServicesResponse>(ListServicesQuery);
  const { categories, loading: categoriesLoading, error: categoriesError } = useCategories();

  if (userLoading && categoriesLoading && serviceLoading) {
    return <Layout>
      <p>{trans('common.loading')}</p>
    </Layout>;
  }

  if (errorUser && categoriesError && errorService) {
    // Rethrow to let the error boundary handle it and show a generic error page
    throw errorUser && categoriesError;
  }

  const users = userData!.User.listMemberForExchanges;
  const services = serviceData!.Service.list;
  const filteredUsers = filterUsers(users, filters);
  const pages = toChunks(filteredUsers, 9);
  const pageUsers = pages[currentPage - 1] ?? [];

  function onSubmitFilters(filters: Filters) {
    const serializedFilters = Object.fromEntries(
      Object.entries(filters).filter(([, filter]) => filter !== null),
    );
    setFilters(filters);
    navigate(route(Page, { page: '1' }, serializedFilters));
  }

  function getCategoryForService(serviceId: string): string | undefined {
    const service = services.find(service => service.uid === serviceId);
    return service ? service.category.uid : undefined;
  }

  function filterUsers(users: User[], filters: Filters) {
    return users.filter(user => {

      if (filters.selectedUsers && filters.selectedUsers.length > 0) {
        if (!filters.selectedUsers.some(userUid => user.uid.includes(userUid))) {
          return false;
        }
      }

      if (filters.selectedCategories && filters.selectedCategories.length > 0) {
        if (!user.providedServices.some(service => {
          const serviceCategory = getCategoryForService(service.uid);
          return serviceCategory && filters.selectedCategories?.includes(serviceCategory);
        })) {
          return false;
        }
      }

      // noinspection RedundantIfStatementJS
      return true;
    });
  }

  return <div className="w-full">
    <h1>Rechercher un service</h1>
    <SearchFilters users={users} categories={categories} onSubmit={onSubmitFilters}/>
    <SearchList pageUsers={pageUsers} currentPage={currentPage} pages={pages} />
  </div>;
}, '/search');

export default Page;

