import styled from '@emotion/styled';
import tw from 'twin.macro';
import Icon from '@app/components/UI/Icon/Icon';

const Avatar = () => {

  return (
    <AvatarStyled>
      <Icon name="user" />
    </AvatarStyled>
  );
};

const AvatarStyled = styled.div`
    ${tw`
    w-[50px]
    h-[50px]
    rounded-full
    bg-primary
    flex
    justify-center
    items-center
    text-white
  `}
`;

export default Avatar;
