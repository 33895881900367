import Select from '@app/components/UI/Form/Select';
import { useQuery } from '@apollo/client';
import React from 'react';
import ListServices from '@graphql/query/service/ListServices.graphql';

interface Service {
  uid: string
  name: string
  category: Category
}

interface Category {
  uid: string
  name: string
}

export type Uid = string;

interface Response {
  Service: {
    list: Service[]
  }
}

function useServices() {
  const { data, loading, error } = useQuery<Response>(ListServices);

  return {
    loading,
    error,
    services: data?.Service.list ?? [],
  };
}

type Props = Omit<React.ComponentProps<typeof Select<string>>, 'options'>;

export default function SelectService({
  id,
  label,
  disabled,
  value,
  onChangedValue,
  errors,
}: Props) {
  const { services } = useServices();

  return <Select<string>
    id={id}
    label={label}
    options={services.map(service => ({ label: service.name, value: service.uid }))}
    disabled={disabled}
    value={value}
    onChangedValue={onChangedValue}
    errors={errors}
  />;
}
