import TextInput from '@app/components/UI/Form/TextInput';
import Range from '@app/components/UI/Form/Range';
import { PropsWithChildren, useState } from 'react';
import tw from 'twin.macro';
import Button, { ButtonGroup } from '@app/components/UI/Button';
import { Exchange } from '@app/pages/Admin/Exchange/ListExchanges/ListExchanges';

export interface Filters {
  service?: string | null
  category?: string | null
  issuer?: string | null
  provider?: string | null
  startDate?: Date | null
  endDate?: Date | null
  minDuration?: number | null
  maxDuration?: number | null
}

interface Props {
  exchanges: readonly Exchange[]
  onSubmit: (filters: Filters) => void
  initialFilters?: Filters
}

export default function ExchangeFilters({
  exchanges,
  onSubmit,
  initialFilters,
}: Props) {

  const [service, setService] = useState<string | null>(initialFilters?.service ?? null);
  const [category, setCategory] = useState<string | null>(initialFilters?.category ?? null);
  const [issuer, setIssuer] = useState<string | null>(initialFilters?.issuer ?? null);
  const [provider, setProvider] = useState<string | null>(initialFilters?.provider ?? null);
  const [startDate, setStartDate] = useState<Date|null>(initialFilters?.startDate ?? null);
  const [endDate, setEndDate] = useState<Date|null>(initialFilters?.endDate ?? null);
  const [minDuration, setMinDuration] = useState<number|null>(initialFilters?.minDuration ?? null);
  const [maxDuration, setMaxDuration] = useState<number|null>(initialFilters?.maxDuration ?? null);

  function clear(e) {
    setService(null);
    setCategory(null);
    setIssuer(null);
    setProvider(null);
    setStartDate(null);
    setEndDate(null);
    setMinDuration(null);
    setMaxDuration(null);

    onSubmit({});
    e.currentTarget.blur();
  }

  function submit(e) {
    e.preventDefault();
    e.currentTarget.blur();
    onSubmit({ service, issuer, provider, startDate, endDate, minDuration, maxDuration, category });
  }

  return <form className="mb-10 max-w-screen-lg">

    <FiltersFieldset value="Filtrer par date">
      <FiltersSection>
        <TextInput<Date> type="date" id="startDate" label="Début" value={startDate} onChangedValue={setStartDate} />
        <TextInput<Date> type="date" id="endDate" label="Fin" value={endDate} onChangedValue={setEndDate} />
      </FiltersSection>
    </FiltersFieldset>

    <FiltersFieldset value="Filtrer par membres impliqués">
      <FiltersSection>
        <TextInput<string> id="issuer"
          label="Email du demandeur"
          value={issuer}
          onChangedValue={setIssuer}
          dataList={Array.from(exchanges
            .map(exchange => [exchange.provider.uid, exchange.issuer.email]),
          )}
        />
        <TextInput<string> id="provider"
          label="Email de l'exécutant"
          value={provider}
          onChangedValue={setProvider}
          dataList={Array.from(exchanges
            .map(exchange => [exchange.provider.uid, exchange.provider.email]),
          )}
        />
      </FiltersSection>
    </FiltersFieldset>
    <FiltersFieldset value="Filtrer par durée">
      <FiltersSection>
        <Range id={'time'} min={1} max={12} minValue={minDuration} maxValue={maxDuration} step={1} label="Durée" formatter={(min, max) => `${min}h-${max}h`} onChange={(min, max) => {
          setMinDuration(min);
          setMaxDuration(max);
        }}/>
      </FiltersSection>
    </FiltersFieldset>

    <FiltersFieldset value="Filtrer par service ou catégorie">
      <FiltersSection>
        <TextInput<string> id="service"
          label="Nom du service"
          value={service}
          onChangedValue={setService}
          dataList={Array.from(exchanges
            .map(exchange => [exchange.service.uid, exchange.service.name]),
          )}
        />

        <TextInput<string> id="category"
          label="Nom de la category"
          value={category}
          onChangedValue={setCategory}
          dataList={Array.from(exchanges
            .map(exchange => [exchange.service.category.uid, exchange.service.category.name]),
          )}
        />
      </FiltersSection>
    </FiltersFieldset>

    <FiltersActions>
      <ButtonGroup>
        <Button variant="neutral" onClick={clear}>Vider les filtres</Button>
        <Button variant="primary" type="submit" onClick={submit}>
          Appliquer les filtres
        </Button>
      </ButtonGroup>
    </FiltersActions>
  </form>;
}

function FiltersFieldset({ value, children }: { value: string } & PropsWithChildren) {
  return <fieldset className="mb-1">
    <legend className="font-bold">{value}</legend>

    {children}
  </fieldset>;
}

const FiltersSection = tw.div`
  grid grid-cols-1 gap-2
  lg:(
    grid grid-cols-3 gap-4
  )
`;

const FiltersActions = tw.div`mt-8`;

export function filterExchanges(exchanges: Exchange[], filters: Filters) {
  return exchanges.filter(exchange => {
    if (filters.startDate && new Date(exchange.date) < filters.startDate) {
      return false;
    }

    if (filters.endDate && new Date(exchange.date) > filters.endDate) {
      return false;
    }

    if (filters.issuer && !exchange.issuer.email.includes(filters.issuer)) {
      return false;
    }

    if (filters.provider && !exchange.provider.email.includes(filters.provider)) {
      return false;
    }

    if (filters.minDuration && exchange.duration < filters.minDuration) {
      return false;
    }

    if (filters.maxDuration && exchange.duration > filters.maxDuration) {
      return false;
    }

    if (filters.service && !exchange.service.name.includes(filters.service)) {
      return false;
    }

    if (filters.category && !exchange.service.category.name.includes(filters.category)) {
      return false;
    }

    // noinspection RedundantIfStatementJS
    return true;
  });
}
