import { useQuery } from '@apollo/client';
import ListServices from '@graphql/query/service/ListServices.graphql';
import Autocomplete from '@app/components/UI/Form/Autocomplete';
import { ComponentProps } from 'react';

interface Service {
  uid: string
  name: string
}

interface ListServicesResponse {
  Service: {
    list: Service[]
  }
}

function useServices() {
  const { data, loading, error } = useQuery<ListServicesResponse>(ListServices);

  return {
    loading,
    error,
    services: data?.Service.list ?? [],
  };
}

type Props = Omit<ComponentProps<typeof Autocomplete>, 'options'>;

export default function MultiselectService(props: Props) {
  const { services } = useServices();

  return <Autocomplete
    options={services.map(service => ({
      value: service.uid,
      label: service.name,
    }))}
    {...props}
  />;
}
