import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { useMemo } from 'react';
import { declareAdminRoute } from '@app/router/router';
import { useMutation } from '@app/api/apollo/useMutation';
import CreateServiceMutation from '@graphql/mutation/service/CreateService.graphql';
import { useNavigate } from 'react-router-dom';
import { onMutateError } from '@graphql/utils';
import { errorsByPath } from '@app/api/errors';
import { AppGraphQLError } from '@app/api/errors/GraphQLErrorCodes';
import { onCreateService } from '@graphql/store/services';
import { route } from '@app/router/generator';
import usePreviousUrlLocationState from '@app/hooks/usePreviousUrlLocationState';
import ServiceForm, { ServiceData } from '@app/components/Form/Admin/Service/ServiceForm';
import UpdateService from '@app/pages/Admin/Service/UpdateService';
import { ROUTE_CREATE_SERVICE } from '@app/paths';

interface MutationResponse {
  Service: {
    create: { uid: string }
  }
}


const CreateServicePage =  declareAdminRoute(function CreateService() {
  useDocumentTitle('Ajout d\'un service');

  const { previousUrl } = usePreviousUrlLocationState();

  const navigate = useNavigate();
  const [mutate, { error }] = useMutation<MutationResponse>(CreateServiceMutation, {
    update(cache, { data }) {
      onCreateService(cache, data!.Service.create);
    },
  });

  const mappedErrors = useMemo(() => ({
    __root: error ? 'Une erreur est survenue lors de la soumission du formulaire.' : undefined,
    ...(error ? errorsByPath(error.graphQLErrors as AppGraphQLError[]) : {}),
  }), [error]);

  async function submit(payload: ServiceData) {
    mutate({
      variables: { payload },
    }).then((response) => {
      navigate(route(UpdateService, { uid: response.data!.Service.create.uid }), {
        state: {
          serviceCreated: true,
          previousUrl,
        },
      });
    }).catch(onMutateError);
  }

  return <div className="w-full">
    <ServiceForm onSubmit={submit} errors={mappedErrors}/>
  </div>;
}, ROUTE_CREATE_SERVICE);

export default CreateServicePage;
