import { ApolloCache } from '@apollo/client';
import ListServicesQuery from '@graphql/query/service/ListServices.graphql';

interface ServiceRef {
  uid: string
}

interface Store {
  Service: {
    list: ServiceRef[]
  }
}

/**
 * Update Apollo store on new service created.
 * Should make the new item visible on the listing.
 *
 * @see https://www.apollographql.com/docs/react/caching/cache-interaction/#using-graphql-queries
 */
export function onCreateService(
  store: ApolloCache<unknown>,
  service: ServiceRef,
) {
  const query = ListServicesQuery;
  // Attempt to read the data from the cache:
  const previousData = store.readQuery<Store>({ query });

  if (!previousData) {
    // If no data was fetched yet, we don't need to do anything
    return;
  }

  const data = {
    ...previousData,
    Service: {
      ...previousData.Service,
      // Append the newly created service to the list:
      list:  [...previousData.Service.list, service],
    },
  };

  // Write the data back to the cache:
  store.writeQuery({ query, data });
}
