import { HTMLAttributes } from 'react';
import tw from 'twin.macro';

interface Props extends HTMLAttributes<HTMLSpanElement> {
  hint?: string
}

export default function LabelHint({ hint }: Props) {
  if (!hint) {
    return null;
  }

  return <StyledLabelHint>
    {hint}
  </StyledLabelHint>;
}

const StyledLabelHint = tw.span`text-sm text-gray-500 block`;

