import { PropsWithChildren } from 'react';
import Header from '@app/components/Header/Header';
import Footer from '@app/components/Footer/Footer';

export default function AppLayout({ children }: PropsWithChildren) {
  return <>
    <Header />

    <main className="flex flex-col p-6 w-full max-w-main-content">
      {children}
    </main>

    <Footer />
  </>;
}
