import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { declareAdminRoute } from '@app/router/router';
import ContentHeader from '@app/components/UI/ContentHeader/ContentHeader';
import Button from '@app/components/UI/Button';
import Icon from '@app/components/UI/Icon/Icon';
import Collapsible from '@app/components/UI/Collapsible';
import { useQuery } from '@apollo/client';
import ListUsersQuery from '@graphql/query/user/ListUsers.graphql';
import { usePageNumberFromQuery } from '@app/hooks/usePageNumber';
import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTE_LIST_USERS } from '@app/paths';
import { trans } from '@app/translations';
import MemberTable from '@app/pages/Admin/Member/ListMembers/MembersTable';
import MembersFilter, { Filters, filterUsers } from '@app/pages/Admin/Member/ListMembers/MembersFilters';
import { route } from '@app/router/generator';
import CreateMember from '@app/pages/Admin/Member/CreateMember/CreateMember';
import Layout from '@app/components/UI/Layout/Layout';

export interface User {
  uid: string
  email: string
  firstname: string
  lastname: string
  phone: string | null
  mobile: string | null
  city: string
  isAdmin: boolean
  isActive: boolean
  createdAt: string
  updatedAt: string
}

interface ListUsersResponse {
  User: {
    list: User[]
  }
}

const Page = declareAdminRoute(function MemberList() {
  useDocumentTitle('Liste des membres');

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const currentPage = usePageNumberFromQuery();
  const [filters, setFilters] = useState<Filters>(unserializeFilters(searchParams));

  const { data, loading, error } = useQuery<ListUsersResponse>(ListUsersQuery);

  function unserializeFilters(searchParams: URLSearchParams): Filters {
    const filters: Filters = {};
    for (const [key, value] of searchParams.entries()) {
      if (['isAdmin', 'isActive'].includes(key)) {
        filters[key] = value === 'true';
        continue;
      }
      if (['startDate', 'endDate'].includes(key)) {
        filters[key] = new Date(value);
        continue;
      }
      filters[key] = value;
    }
    return filters;
  }

  function onSubmitFilters(filters: Filters) {
    setFilters(filters);
    const serializedFilters = Object.fromEntries(Object.entries(filters).map(([key, filter]) => {
      if (typeof filter === 'boolean'){
        return [key, filter ? 'true' : 'false'];
      }
      if (filter instanceof Date) {
        return [key, filter.toISOString()];
      }
      return [key, filter];
    }).filter(([, filter]) => filter !== null));

    // Reset to first page:
    navigate(route(Page, { page: '1' }, serializedFilters));
  }

  if (loading) {
    // TODO: better loading state, with a dedicated skeleton or generic loader?
    return <Layout>
      <p>{trans('common.loading')}</p>
    </Layout>;
  }

  if (error) {
    // Rethrow to let the error boundary handle it and show a generic error page
    throw error;
  }

  const users = data!.User.list;
  const filteredUsers = sortUsers(filterUsers(users, filters));
  const previousUrl = location.pathname + location.search;

  return <Layout>
    <Collapsible initialOpened={Object.values(filters).length > 0}>
      {({ opened, toggle }) => {
        return <>
          <ContentHeader>
            <ContentHeader.Title>
              <h1>Liste des utilisateurs</h1>
            </ContentHeader.Title>

            <ContentHeader.Actions>
              <Collapsible.Trigger toggle={toggle} opened={opened}>
                <Button variant="neutral">
                  <Icon name="filter" />&nbsp;{opened ? 'Cacher les filtres' : 'Modifier les filtres'}
                </Button>
              </Collapsible.Trigger>

              <Button
                variant="primary" href={route(CreateMember)} state={{
                  previousUrl,
                }}
              >
                Ajouter un utilisateur
              </Button>
            </ContentHeader.Actions>
          </ContentHeader>

          <Collapsible.Pane opened={opened}>
            <MembersFilter initialFilters={filters} users={filteredUsers} onSubmit={onSubmitFilters} />
          </Collapsible.Pane>
        </>;
      }}
    </Collapsible>

    <MemberTable previousUrl={previousUrl} users={filteredUsers} currentPage={currentPage} />
  </Layout>;
}, ROUTE_LIST_USERS);

export default Page;

/**
 * Sort users by email, alphabetically.
 */
function sortUsers(users: User[]) {
  return users.sort((a, b) => a.email.localeCompare(b.email));
}
