import Link from '@app/components/Router/Link';
import { route } from '@app/router/generator';
import Login from '@app/pages/Auth/Login';

interface Props {
  title: string
  subtitle: string
  lead: string
  content: string
}

export default function ErrorPageContent({
  title,
  subtitle,
  lead,
  content,
}: Props) {
  return <div className="fr-container">
    <div className="fr-grid-row fr-grid-row--gutters">
      <div className="fr-col fr-mt-14w">
        <h1>{title}</h1>
        <p className="fr-text--sm">{subtitle}</p>
        <p className="fr-text--lead">{lead}</p>
        <p>{content}</p>
        <ul className="fr-btns-group fr-btns-group--inline">
          <li>
            <Link to={route(Login)} className="fr-btn">Page d&apos;authentification</Link>
          </li>
          <li>
            <a href={`mailto:${AppConfig.APP_CONTACT_EMAIL}`} className="fr-btn fr-btn--secondary">Contactez-nous</a>
          </li>
        </ul>
      </div>
      <div className="fr-col-lg-4 fr-mt-10w">
      </div>
    </div>
  </div>;
}
