import Button from '@app/components/UI/Button';
import { route } from '@app/router/generator';
import ExchangeList from '@app/pages/Admin/Exchange/ListExchanges/ListExchanges';
import tw from 'twin.macro';
import TextInput from '@app/components/UI/Form/TextInput';
import { FormErrorsMap } from '@app/components/UI/Form';
import { Exchange } from '@app/models/types/Exchange';
import usePreviousUrlLocationState from '@app/hooks/usePreviousUrlLocationState';
import { FormEvent, useState } from 'react';
import { FormRootErrors } from '@app/components/UI/Form/FormRootErrors';
import SelectMember from '@app/components/Form/Admin/Member/SelectMember';
import SelectService from '@app/components/Form/Admin/Service/SelectService';
import TextArea from '@app/components/UI/Form/TextArea';
import { hoursFromDuration, minutesFromDuration, toDuration } from '@app/utils/duration';

export interface ExchangeData {
  service?: string | null
  issuer?: string | null
  provider?: string | null
  date?: Date | null
  duration?: number | null
  comment?: string | null
}

interface Props {
  onSubmit: (payload: ExchangeData) => void
  errors?: FormErrorsMap<keyof ExchangeData>
}

interface CreateProps extends Props{
  initialData?: never
}
interface UpdateProps extends Props{
  initialData: Exchange
}


export default function ExchangeForm({
  onSubmit,
  initialData= undefined,
  errors = {},
}: CreateProps | UpdateProps) {
  const isUpdate = initialData !== undefined;
  const FormActions = tw.div`flex items-center justify-end mt-10`;
  const { previousUrl } =  usePreviousUrlLocationState();

  const [service, setServiceUid] = useState<string|null>(initialData?.service.uid ?? null);
  const [issuer, setIssuerUid] = useState<string|null>(initialData?.issuer.uid ?? null);
  const [provider, setProviderUid] = useState<string|null>(initialData?.provider.uid ?? null);
  const [date, setDate] = useState<Date|null>(initialData?.date ? new Date(initialData.date) : null);
  const [hours, setHours] = useState<number>(hoursFromDuration(initialData?.duration ?? 0));
  const [minutes, setMinutes] = useState<number>(minutesFromDuration(initialData?.duration ?? 0));
  const [comment, setComment] = useState<string|null>(initialData?.comment ?? null);

  function submit(event: FormEvent<HTMLFormElement>): void {
    // Prevent default to avoid page reload.
    event.preventDefault();
    const duration = toDuration(hours, minutes);

    onSubmit({
      service,
      issuer,
      provider,
      date,
      duration,
      comment,
    });
  }

  return (
    <form className="mb-10 max-w-screen-lg" onSubmit={submit}>

      <Button href={previousUrl ?? route(ExchangeList)}>Retour à la liste</Button>

      <FormRootErrors errors={errors?.__root} />

      <fieldset className="mb-1">
        <legend className="font-bold">Personnes impliquées</legend>

        <SelectMember
          id="issuer"
          label="Demandeur"
          value={issuer}
          onChangedValue={setIssuerUid} />

        <SelectMember
          id="provider"
          label="Exécutant"
          value={provider}
          onChangedValue={setProviderUid} />

      </fieldset>

      <fieldset className="mb-1 flex gap-2">
        <legend className="font-bold">Durée</legend>
        <TextInput<number>
          id="durationHours"
          label="Heures"
          type="number"
          InputProps={{
            min: 0,
          }}
          value={hours}
          onChangedValue={(value) => setHours(value ?? 0)}
        />
        <TextInput<number>
          id="durationMinutes"
          label="Minutes"
          type="number"
          InputProps={{
            min: 0,
            max: 45,
            step: 15,
          }}
          value={minutes}
          onChangedValue={(value) => setMinutes(value ?? 0)}
        />
      </fieldset>

      <fieldset className="mb-1">
        <legend className="font-bold">Informations</legend>

        <SelectService
          id="service"
          label="Service rendu"
          value={service}
          onChangedValue={setServiceUid}
        />

        <TextInput<Date>
          type="date"
          id="startDate"
          label="Date"
          value={date}
          onChangedValue={setDate}
          InputProps={{ max: new Date().toISOString().split('T')[0] }}
        />

        <TextArea
          id="comment"
          label="Commentaire"
          placeholder="300 caractères maximum."
          value={comment}
          onChangedValue={setComment}
        />
      </fieldset>

      <FormActions>
        <Button type="submit" variant="primary">
          {isUpdate ? 'Modifier' : 'Créer'}
        </Button>
      </FormActions>
    </form>
  );
}
