import { PropsWithChildren, useState } from 'react';
import tw from 'twin.macro';
import TextInput from '@app/components/UI/Form/TextInput';
import Button, { ButtonGroup } from '@app/components/UI/Button';
import { User } from '@app/pages/Admin/Member/ListMembers/ListMembers';
import ToggleInput from '@app/components/UI/Form/ToggleInput';

export interface Filters {
  email?: string | null
  firstname?: string | null
  lastname?: string | null
  city?: string | null
  isAdmin?: boolean
  isActive?: boolean
  startDate?: Date|null
  endDate?: Date|null
}

interface Props {
  users: readonly User[]
  onSubmit: (filters: Filters) => void
  initialFilters?: Filters
}

export default function UsersFilters({
  users,
  onSubmit,
  initialFilters,
}: Props) {
  const [email, setEmail] = useState<string | null>(initialFilters?.email ?? null);
  const [firstname, setFirstname] = useState<string | null>(initialFilters?.firstname ?? null);
  const [lastname, setLastname] = useState<string | null>(initialFilters?.lastname ?? null);
  const [city, setCity] = useState<string | null>(initialFilters?.city ?? null);
  const [isAdmin, setIsAdmin] = useState<boolean>(initialFilters?.isAdmin ?? false);
  const [isActive, setIsActive] = useState<boolean>(initialFilters?.isActive ?? false);
  const [startDate, setStartDate] = useState<Date|null>(initialFilters?.startDate ?? null);
  const [endDate, setEndDate] = useState<Date|null>(initialFilters?.endDate ?? null);

  function clear(e) {
    setEmail(null);
    setFirstname(null);
    setLastname(null);
    setCity(null);
    setStartDate(null);
    setEndDate(null);
    setIsAdmin(false);
    setIsActive(false);

    onSubmit({});
    e.currentTarget.blur();
  }

  function submit(e) {
    e.preventDefault();
    e.currentTarget.blur();
    onSubmit({ email, firstname, lastname, city, isAdmin, isActive, startDate, endDate });
  }

  return <form className="mb-10 max-w-screen-lg">
    <FiltersFieldset value="Filtrer par email ou par ville">
      <FiltersSection>
        <TextInput<string> id="email"
          label="Email"
          value={email}
          onChangedValue={setEmail}
          dataList={users.map(user => [user.uid, user.email])}
        />
        <TextInput<string> id="city"
          label="Ville"
          value={city}
          onChangedValue={setCity}
          dataList={users.map(user => [user.uid, user.city])}
        />
      </FiltersSection>
    </FiltersFieldset>

    <FiltersFieldset value="Filtrer par prénom ou nom">
      <FiltersSection>
        <TextInput<string> id="firstname"
          label="Prénom"
          value={firstname}
          onChangedValue={setFirstname}
          dataList={users.map(user => [user.uid, user.firstname])}
        />
        <TextInput<string> id="lastname"
          label="Nom"
          value={lastname}
          onChangedValue={setLastname}
          dataList={users.map(user => [user.uid, user.lastname])}
        />
      </FiltersSection>
    </FiltersFieldset>

    <FiltersFieldset value="Filtrer par date d'ajout">
      <FiltersSection>
        <TextInput<Date> type="date" id="startDate" label="Début" value={startDate} onChangedValue={setStartDate} />
        <TextInput<Date> type="date" id="endDate" label="Fin" value={endDate} onChangedValue={setEndDate} />
      </FiltersSection>
    </FiltersFieldset>

    <FiltersFieldset value="Filtrer par type">
      <FiltersSection>
        <ToggleInput id="isAdmin" label="Est Admin" checked={isAdmin} onChangedValue={setIsAdmin} />
        <ToggleInput id="isActive" label="Est actif" checked={isActive} onChangedValue={setIsActive} />
      </FiltersSection>
    </FiltersFieldset>

    <FiltersActions>
      <ButtonGroup>
        <Button variant="neutral" onClick={clear}>Vider les filtres</Button>
        <Button variant="primary" type="submit" onClick={submit}>
          Appliquer les filtres
        </Button>
      </ButtonGroup>
    </FiltersActions>
  </form>;
}

function FiltersFieldset({ value, children }: { value: string } & PropsWithChildren) {
  return <fieldset className="mb-1">
    <legend className="font-bold">{value}</legend>

    {children}
  </fieldset>;
}

const FiltersSection = tw.div`
  grid grid-cols-1 gap-2
  lg:(
    grid grid-cols-3 gap-4
  )
`;

const FiltersActions = tw.div`mt-8`;

export function filterUsers(users: User[], filters: Filters) {
  return users.filter(user => {

    if (filters.email && !user.email.includes(filters.email)) {
      return false;
    }

    if (filters.firstname && !user.firstname.includes(filters.firstname)) {
      return false;
    }

    if (filters.lastname && !user.lastname.includes(filters.lastname)) {
      return false;
    }

    if (filters.city && !user.city.includes(filters.city)) {
      return false;
    }

    if (filters.isActive && !user.isActive === filters.isActive) {
      return false;
    }

    if (filters.isAdmin && !user.isAdmin === filters.isAdmin) {
      return false;
    }

    if (filters.startDate && new Date(user.createdAt) < filters.startDate) {
      return false;
    }

    if (filters.endDate && new Date(user.createdAt) > filters.endDate) {
      return false;
    }

    // noinspection RedundantIfStatementJS
    return true;
  });
}
