import { ApolloCache } from '@apollo/client';
import ListExchangesQuery from '@graphql/query/exchange/ListExchange.graphql';
import { Types } from '@app/api/apollo/typePolicies';

interface ExchangeRef {
  uid: string
}

interface Store {
  Exchange: {
    list: ExchangeRef[]
  }
}

/**
 * Update Apollo store on new exchange created.
 * Should make the new item visible on the listing.
 *
 * @see https://www.apollographql.com/docs/react/caching/cache-interaction/#using-graphql-queries
 */
export function onCreatedExchange(
  store: ApolloCache<unknown>,
  exchange: ExchangeRef,
) {
  const query = ListExchangesQuery;
  // Attempt to read the data from the cache:
  const previousData = store.readQuery<Store>({ query });

  if (!previousData) {
    // If no data was fetched yet, we don't need to do anything
    return;
  }

  const data = {
    ...previousData,
    Exchange: {
      ...previousData.Exchange,
      // Append the newly created exchange to the list:
      list:  [...previousData.Exchange.list, exchange],
    },
  };

  // Write the data back to the cache:
  store.writeQuery({ query, data });
}

export function onDeleteExchange(
  store: ApolloCache<unknown>,
  exchangeUid: string,
) {
  store.evict({
    id: store.identify({
      __typename: Types.Exchange,
      uid: exchangeUid,
    }),
  });
  store.gc();
}
