import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { useMemo } from 'react';
import { declareAdminRoute } from '@app/router/router';
import { useMutation } from '@app/api/apollo/useMutation';
import UpdateUserMutation from '@graphql/mutation/user/UpdateUser.graphql';
import FindUserQuery from '@graphql/query/user/FindUser.graphql';
import { useLocation, useParams } from 'react-router-dom';
import { onMutateError } from '@graphql/utils';
import { errorsByPath } from '@app/api/errors';
import { AppGraphQLError } from '@app/api/errors/GraphQLErrorCodes';
import UnexpectedError from '@app/errors/UnexpectedError';
import { trans } from '@app/translations';
import { useQuery } from '@apollo/client';
import { useNotFoundHandler } from '@app/components/ErrorBoundary';
import { User } from '@app/models/types/User';
import MemberForm, { UserData } from '@app/components/Form/Admin/Member/MemberForm';
import Alert from '@app/components/UI/Alert';
import { ROUTE_UPDATE_USER } from '@app/paths';

interface MutationResponse {
  User: {
    update: {
      uid: string
    }
  }
}

interface FindUserQueryResponse {
  User: {
    find: User
  }
}

interface RedirectFromCreationState {
  userCreated: boolean
}

const Page = declareAdminRoute(function UpdateMember() {
  useDocumentTitle('Modifier un utilisateur');

  const { uid } = useParams();
  const notFoundHandler = useNotFoundHandler();
  const { userCreated = false } = (useLocation().state ?? {}) as RedirectFromCreationState;

  const query = useQuery<FindUserQueryResponse>(FindUserQuery, {
    variables: { uid },
    context: {
      // On GraphQL Not Found error, show a Not Found page
      onNotFound: notFoundHandler,
    },
  });

  const [mutate, mutationState] = useMutation<MutationResponse>(UpdateUserMutation);

  const mappedErrors = useMemo(() => {
    const error = mutationState.error;

    return {
      __root: error ? 'Une erreur est survenue lors de la soumission du formulaire.' : undefined,
      ...(error ? errorsByPath(error.graphQLErrors as AppGraphQLError[]) : {}),
    };
  }, [mutationState.error]);

  async function submit(payload: UserData) {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    mutate({
      variables: { uid, payload },
    }).catch(onMutateError);
  }

  if (query.error) {
    throw new UnexpectedError(query.error.message, query.error);
  }

  if (query.loading) {
    // TODO: better loading state, with a dedicated skeleton or generic loader?
    return <p>{trans('common.loading')}</p>;
  }

  const user = query.data!.User.find;

  return <div className="w-full">
    {userCreated && !mutationState.called && <Alert variant="success">
      Utilisateur créé avec succès.
    </Alert>}

    {mutationState.called && mutationState.data?.User && <Alert variant="success">
      Utilisateur modifié avec succès.
    </Alert>}

    <MemberForm onSubmit={submit} errors={mappedErrors}
      initialData={{
        email: user.email,
        civility: user.civility,
        firstname: user.firstname,
        lastname: user.lastname,
        birthYear: user.birthYear,
        address: user.address,
        city: user.city,
        postCode: user.postCode,
        phone: user.phone,
        mobile: user.mobile,
        comment: user.comment,
        details: user.details,
        providedServices: user.providedServices.map(service => service.uid),
        searchedServices: user.searchedServices.map(service => service.uid),
        startingCapital: user.startingCapital,
        paidMembershipDate: user.paidMembershipDate ? new Date(user.paidMembershipDate) : null,
        providedInsuranceCertificateDate: user.providedInsuranceCertificateDate ? new Date(user.providedInsuranceCertificateDate) : null,
        signedCharterDate: user.signedCharterDate ? new Date(user.signedCharterDate) : null,
        active: user.isActive,
        admin: user.isAdmin,
      }}
      informativeProps={{
        uid: user.uid,
        createdAt: user.createdAt ? new Date(user.createdAt) : null,
        updatedAt: user.updatedAt ? new Date(user.updatedAt) : null,
      }}
    />

  </div>;
}, ROUTE_UPDATE_USER );

export default Page;
