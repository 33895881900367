import tw from 'twin.macro';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { SortOrder } from '@app/hooks/useSortHook';

interface Props {
  headers: string[]
  rows: React.ReactNode[]
  onHeaderClick: (column: string) => void
  sortColumn: string | null
  sortOrder: SortOrder
  sortSettings: { [column: string]: boolean }
}

export default function Table({
  headers,
  rows,
  onHeaderClick,
  sortColumn,
  sortOrder,
  sortSettings,

}: Props) {

  return (

    <Container>
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th className={clsx(sortSettings[header] && 'cursor-pointer')} key={header} onClick={() => sortSettings[header] && onHeaderClick(header)}>
                {header}{' '}
                {sortSettings[header] && sortColumn === header && (sortOrder === 'asc' ? '▲' : '▼')}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>{rows.map((row) => row)}</tbody>
      </table>
    </Container>
  );
}

const Container = styled.div`
  ${tw`
    border
    rounded-xl
    overflow-hidden
    mb-12
  `}
  // Allow horizontal scrolling on small screens
  ${tw`max-lg:(overflow-x-auto)`}
`;
