import { Modal } from '@mui/base';
import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import tw from 'twin.macro';
import clsx from 'clsx';
import Icon from '@app/components/UI/Icon/Icon';
import Button from '@app/components/UI/Button';

interface Props {
  opened: boolean
  close: () => void
}

export default function ModalUI({ children, opened, close }: PropsWithChildren<Props>) {
  return (
    <StyledModal open={opened} onClose={close} slots={{ backdrop: StyledBackdrop }}>
      <ModalCard>
        <ButtonCrossStyled onClick={close}><Icon name="cross" /></ButtonCrossStyled>
        <div className="lg:max-h-[calc(100vh-300px)] max-h-[90vh] overflow-auto">
          {children}
        </div>
      </ModalCard>
    </StyledModal>
  );
}

// eslint-disable-next-line react/display-name
const Backdrop = React.forwardRef<
  HTMLDivElement,
  { opened?: boolean, className: string }
>((props, ref) => {
  const { opened, className, ...other } = props;
  return (
    <div
      className={clsx({ 'MuiBackdrop-open': opened }, className)}
      ref={ref}
      {...other}
    />
  );
});

const StyledModal = styled(Modal)`
  ${tw`
    fixed
    top-0
    left-0
    right-0
    bottom-0
    z-50
  `}
`;

const StyledBackdrop = styled(Backdrop)`
  ${tw`
    -z-[1]
    fixed
    top-0
    left-0
    bottom-0
    right-0
    bg-gray-700/[0.5]
  `}
`;

const ModalCard = styled.div`
  ${tw`
    lg:relative
    lg:w-[750px]
    lg:px-12
    lg:top-[100px]
    lg:left-[50%]
    lg:-translate-x-1/2
    lg:max-h-[calc(100vh-200px)]
    bg-white
    py-12
    px-6
    fixed
    top-0
    right-0
    bottom-0
    left-0
  `}
`;

const ButtonCrossStyled = styled(Button)`
  ${tw`
    fixed
    z-40
    right-0
    top-5
  `}
`;
