import UserMenu from '@app/components/UI/Navbar/UserMenu';
import tw from 'twin.macro';
import { useState } from 'react';
import ResponsiveMenu from '@app/components/UI/Navbar/ResponsiveMenu';
import UTPALogo from '@images/logo.png';
import PrimaryNav from '@app/components/UI/Navbar/PrimaryNav';


export default function Header() {
  const [opened, setOpened] = useState<boolean>(false);

  return <header>
    <Header.Container role="banner" >
      <Header.Brand>
        <img src={UTPALogo} alt="UTPA logo" className="max-w-full max-h-12" />
        <span className="text-primary font-bold pl-4">Un temps pour un autre</span>
      </Header.Brand>

      <ResponsiveMenu opened={opened} setOpened={setOpened}>
        <PrimaryNav setOpened={setOpened}/>
        <UserMenu />
      </ResponsiveMenu>
    </Header.Container>
  </header>;
}

Header.Brand = tw.div`
  relative
  flex
  items-center
  md:w-96
  pl-8
 `;

Header.Container = tw.div`
  flex
  justify-between
  border-solid
  border-gray-200
  border-b
  bg-white
  h-14
  relative
  `;
