import TextInput from '@app/components/UI/Form/TextInput';
import tw from 'twin.macro';
import { PropsWithChildren, useState } from 'react';
import { Exchange } from '@app/models/types/Exchange';
import Button, { ButtonGroup } from '@app/components/UI/Button';
import { UserProfile } from '@app/hooks/useAuthContext';
import SelectRole from '@app/components/Form/Member/Exchange/SelectRole';

export interface Filters {
  role?: string | null
  startDate?: Date | null
  endDate?: Date | null
  service?: string | null
  issuer?: string | null
  provider?: string | null
}

interface Props {
  exchanges: readonly Exchange[]
  onSubmit: (filters: Filters) => void
  initialFilters?: Filters
}

export default function ExchangesFilters({
  exchanges,
  onSubmit,
  initialFilters,
}: Props) {

  const [role, setRole] = useState<string | null>(initialFilters?.role ?? null);
  const [startDate, setStartDate] = useState<Date | null>(initialFilters?.startDate ?? null);
  const [endDate, setEndDate] = useState<Date | null>(initialFilters?.endDate ?? null);
  const [service, setService] = useState<string | null>(initialFilters?.service ?? null);
  const [issuer, setIssuer] = useState<string | null>(initialFilters?.issuer ?? null);
  const [provider, setProvider] = useState<string | null>(initialFilters?.provider ?? null);

  function clear(e) {
    setRole(null);
    setService(null);
    setIssuer(null);
    setProvider(null);
    setStartDate(null);
    setEndDate(null);

    onSubmit({});
    e.currentTarget.blur();
  }

  function submit(e) {
    e.preventDefault();
    e.currentTarget.blur();
    onSubmit({ role, service, issuer, provider, startDate, endDate });
  }

  return <form className="mb-10 max-w-screen-lg">
    <FiltersFieldset value="Filtrer par rôle">
      <FiltersSection>
        <SelectRole
          id="role"
          label="Rôle"
          value={role}
          onChangedValue={setRole}
        />
      </FiltersSection>
    </FiltersFieldset>
    <FiltersFieldset value="Filtrer par service ou membre impliqué">
      <FiltersSection>
        <TextInput<string> id="service"
          label="Nom du service"
          value={service}
          onChangedValue={setService}
          dataList={exchanges.map(exchange => [exchange.service.uid, exchange.service.name])}
        />
      </FiltersSection>
      <FiltersSection>
        <TextInput<string> id="issuer"
          label="Email du membre demandeur"
          value={issuer}
          onChangedValue={setIssuer}
          dataList={exchanges.map(exchange => [exchange.uid, exchange.issuer.email])}
        />
        <TextInput<string> id="provider"
          label="Email du membre exécutant"
          value={provider}
          onChangedValue={setProvider}
          dataList={exchanges.map(exchange => [exchange.uid, exchange.provider.email])}
        />
      </FiltersSection>
    </FiltersFieldset>

    <FiltersFieldset value="Filtrer par période">
      <FiltersSection>
        <TextInput<Date> id="startDate"
          label="Date de début"
          value={startDate}
          onChangedValue={setStartDate}
          type="date"
        />
        <TextInput<Date> id="endDate"
          label="Date de fin"
          value={endDate}
          onChangedValue={setEndDate}
          type="date"
        />
      </FiltersSection>
    </FiltersFieldset>

    <FiltersActions>
      <ButtonGroup>
        <Button variant="neutral" onClick={clear}>Vider les filtres</Button>
        <Button variant="primary" type="submit" onClick={submit}>
            Appliquer les filtres
        </Button>
      </ButtonGroup>
    </FiltersActions>
  </form>;
}

function FiltersFieldset({ value, children }: { value: string } & PropsWithChildren) {
  return <fieldset className="mb-1">
    <legend className="font-bold">{value}</legend>

    {children}
  </fieldset>;
}

const FiltersSection = tw.div`
  grid grid-cols-1 gap-2
  lg:(
    grid grid-cols-3 gap-4
  )
`;

const FiltersActions = tw.div`mt-8`;

export function filterExchanges(exchanges: Exchange[], filters: Filters, profile: UserProfile | null) {
  return exchanges.filter(exchange => {

    if (filters.startDate && new Date(exchange.date) < filters.startDate) {
      return false;
    }

    if (filters.endDate && new Date(exchange.date) > filters.endDate) {
      return false;
    }

    if (filters.issuer && !exchange.issuer.email.includes(filters.issuer)) {
      return false;
    }

    if (filters.provider && !exchange.provider.email.includes(filters.provider)) {
      return false;
    }

    if (filters.service && !exchange.service.name.includes(filters.service)) {
      return false;
    }

    if (filters.role === 'issuer' && exchange.provider.uid === profile?.uid) {
      return false;
    }

    if (filters.role === 'provider' && exchange.issuer.uid === profile?.uid) {
      return false;
    }

    // noinspection RedundantIfStatementJS
    return true;
  });
}
