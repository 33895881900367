import styled from '@emotion/styled';
import tw from 'twin.macro';

const Tag = ({ title }) => {
  return (
    <TagStyled>
      {title}
    </TagStyled>
  );
};

const TagStyled = styled.div`
  ${tw`
    py-[0.313rem]
    px-[0.625rem]
    bg-primary
    text-white
    w-fit
    text-xs
    text-center
    font-semibold
    leading-tight
    uppercase
    rounded-[5px]
  `}
`;

export default Tag;
