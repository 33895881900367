import { ReactHTML } from 'react';
import { TwComponent } from 'twin.macro';

interface Props {
  name: string
  Component?: keyof ReactHTML | TwComponent<keyof ReactHTML>
}

export default function Icon({
  Component = 'span',
  name,
}: Props) {
  return <Component className={`icon icon--${name}`} aria-hidden="true" />;
}
