import tw from 'twin.macro';
import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { findSlotOfType } from '@app/utils/slots';
import { withDisplayName } from '@app/utils/displayName';

const Container = styled(tw.div`
  flex items-center justify-between mb-7 lg:flex-row flex-col
`)`
  h1, h2, h3, h4, h5, h6 {
    ${tw`m-0 mb-2`}
  }
`;
const Title = tw.div`flex flex-col mr-9`;
const Actions = styled(tw.div`flex`)`
  // Space between actions:
  > * {
    ${tw`!ml-5`}
  }
`;

function ContentHeader({ children }: PropsWithChildren) {
  const TitleEl = findSlotOfType(children, Title);
  const ActionsEl = findSlotOfType(children, Actions);

  return <ContentHeader.Container>
    {TitleEl}
    {ActionsEl}
  </ContentHeader.Container>;
}

ContentHeader.Container = withDisplayName(Container, 'ContentHeader.Container');
ContentHeader.Title = withDisplayName(Title, 'ContentHeader.Title');
ContentHeader.Actions = withDisplayName(Actions, 'ContentHeader.Actions');

export default ContentHeader;
