import Table, { TableActionsButton } from '@app/components/UI/Table';
import Pagination from '@app/components/UI/Pagination';
import { toChunks } from '@app/utils/array';
import MemberList, { User } from '@app/pages/Admin/Member/ListMembers/ListMembers';
import { route } from '@app/router/generator';
import DropMenu from '@app/components/UI/DropMenu';
import Link from '@app/components/Router/Link';
import UpdateMember from '@app/pages/Admin/Member/UpdateMember/UpdateMember';
import { WithPreviousUrl } from '@app/hooks/usePreviousUrlLocationState';
import Dot from '@app/components/UI/Icon/Dot';
import { useSortHook } from '@app/hooks/useSortHook';
import { compareStrings } from '@app/utils/sort';
import { useCallback, useMemo } from 'react';

interface Props {
  users: readonly User[]
  currentPage: number
  perPage?: number
}

export default function UsersTable({
  users,
  currentPage,
  perPage = 10,
  previousUrl,
}: WithPreviousUrl<Props>) {
  const { sortColumn, sortOrder, handleSort } = useSortHook();

  const sortSettings = {
    'Email': true,
    'Prénom': true,
    'Nom': true,
    'Ville': true,
  };

  const getSortedUsers = useCallback(() => {
    return [...users].sort((a, b) => {
      switch (sortColumn) {
        case 'Email':
          return compareStrings(a.email, b.email, sortOrder);
        case 'Prénom':
          return compareStrings(a.firstname, b.firstname, sortOrder);
        case 'Nom':
          return compareStrings(a.lastname, b.lastname, sortOrder);
        case 'Ville':
          return compareStrings(a.city, b.city, sortOrder);
        default:
          return 0;
      }
    });
  }, [sortColumn, sortOrder, users]);

  const sortedUsers = useMemo(() => getSortedUsers(), [getSortedUsers]);

  const pages = toChunks(sortedUsers, perPage);
  const pageUsers = pages[currentPage - 1] ?? [];

  return <>
    <Table
      headers={[
        'Email',
        'Prénom',
        'Nom',
        'N° de téléphone fixe',
        'N° de téléphone portable',
        'Ville',
        'Rôle(s)',
        'Actif',
        '',
      ]}
      onHeaderClick={handleSort}
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      sortSettings={sortSettings}
      rows={pageUsers.map(user => <TableItem
        previousUrl={previousUrl}
        key={user.uid}
        user={user}
      />)}
    />

    <Pagination current={currentPage} pages={Object.keys(pages).map(pageIdx => route(MemberList, {}, {
      page: String(parseInt(pageIdx) + 1),
    }))} />
  </>;
}

function TableItem({ user, previousUrl }: WithPreviousUrl<{
  user: User
}> ) {
  return <tr>
    <td>{user.email}</td>
    <td>{user.firstname}</td>
    <td>{user.lastname}</td>
    <td>{user.phone}</td>
    <td>{user.mobile}</td>
    <td>{user.city}</td>
    <td>{user.isAdmin ? 'admin' : 'user'}</td>
    <td>{user.isActive ? <Dot variant="active"> Oui</Dot> : <Dot variant="inactive"> Non</Dot>} </td>
    <td className="text-right">
      <TableItemMenu uid={user.uid} previousUrl={previousUrl}/>
    </td>
  </tr>;
}

function TableItemMenu({ uid, previousUrl }: WithPreviousUrl<{ uid: string }>) {
  return <DropMenu>
    <DropMenu.Trigger>
      <TableActionsButton />
    </DropMenu.Trigger>
    <DropMenu.Items>
      <DropMenu.Item>
        <Link to={route(UpdateMember, { uid } )} state={{ previousUrl }}>
          Modifier
        </Link>
      </DropMenu.Item>
    </DropMenu.Items>
  </DropMenu>;
}

