import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { declareAdminRoute } from '@app/router/router';
import { useQuery } from '@apollo/client';
import ListCategoriesQuery from '@graphql/query/category/ListCategories.graphql';
import { usePageNumberFromQuery } from '@app/hooks/usePageNumber';
import { useLocation } from 'react-router-dom';
import { ROUTE_LIST_CATEGORIES } from '@app/paths';
import { trans } from '@app/translations';
import CategoryTable from '@app/pages/Admin/Category/ListCategories/CategoriesTable';
import { route } from '@app/router/generator';
import Button from '@app/components/UI/Button';
import CreateCategory from '@app/pages/Admin/Category/CreateCategory';
import ContentHeader from '@app/components/UI/ContentHeader';
import Layout from '@app/components/UI/Layout/Layout';

export interface Category {
  uid: string
  name: string
  isActive: boolean
}

interface ListCategoriesResponse {
  Category: {
    list: Category[]
  }
}

const Page = declareAdminRoute(function ListCategories() {
  useDocumentTitle('Liste des categories');

  const location = useLocation();
  const currentPage = usePageNumberFromQuery();

  const { data, loading, error } = useQuery<ListCategoriesResponse>(ListCategoriesQuery);

  if (loading) {
    // TODO: better loading state, with a dedicated skeleton or generic loader?
    return <Layout>
      <p>{trans('common.loading')}</p>
    </Layout>;
  }

  if (error) {
    // Rethrow to let the error boundary handle it and show a generic error page
    throw error;
  }

  const categories = data!.Category.list;
  const filteredCategories = sortCategories(categories);
  const previousUrl = location.pathname + location.search;

  return <Layout>
    <ContentHeader>
      <ContentHeader.Title>
        <h1>Liste des Catégories</h1>
      </ContentHeader.Title>

      <ContentHeader.Actions>

        <Button
          variant="primary" href={route(CreateCategory)} state={{
            previousUrl,
          }}
        >
          Ajouter une catégorie
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>

    <CategoryTable previousUrl={previousUrl} categories={filteredCategories} currentPage={currentPage} />
  </Layout>;
}, ROUTE_LIST_CATEGORIES);

export default Page;

/**
 * Sort service by name, alphabetically.
 */
function sortCategories(categories: Category[]) {
  const toSort = Array.from(categories);
  return toSort.sort((a, b) => a.name.localeCompare(b.name));
}
