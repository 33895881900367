import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { declareAdminRoute } from '@app/router/router';
import { useQuery } from '@apollo/client';
import ListServicesQuery from '@graphql/query/service/ListServices.graphql';
import { usePageNumberFromQuery } from '@app/hooks/usePageNumber';
import { useLocation } from 'react-router-dom';
import { ROUTE_LIST_SERVICES } from '@app/paths';
import { trans } from '@app/translations';
import ServiceTable from '@app/pages/Admin/Service/ListServices/ServicesTable';
import { route } from '@app/router/generator';
import Button from '@app/components/UI/Button';
import CreateService from '@app/pages/Admin/Service/CreateService';
import ContentHeader from '@app/components/UI/ContentHeader';
import Layout from '@app/components/UI/Layout/Layout';

export interface Category {
  uid: string
  name: string
  isActive: boolean
}

export interface Service {
  uid: string
  name: string
  isActive: boolean
  isVisible: boolean
  category: Category
}

interface ListServicesResponse {
  Service: {
    list: Service[]
  }
}

const Page = declareAdminRoute(function ListService() {
  useDocumentTitle('Liste des services');

  const location = useLocation();
  const currentPage = usePageNumberFromQuery();

  // TODO: RELOAD DATAS AFTER CATEGORY MUTATION ?
  const { data, loading, error } = useQuery<ListServicesResponse>(ListServicesQuery);

  if (loading) {
    // TODO: better loading state, with a dedicated skeleton or generic loader?
    return <Layout>
      <p>{trans('common.loading')}</p>
    </Layout>;
  }

  if (error) {
    // Rethrow to let the error boundary handle it and show a generic error page
    throw error;
  }

  const services = data!.Service.list;
  const filteredServices = sortServices(services);
  const previousUrl = location.pathname + location.search;

  return <Layout>
    <ContentHeader>
      <ContentHeader.Title>
        <h1>Liste des Services</h1>
      </ContentHeader.Title>

      <ContentHeader.Actions>

        <Button
          variant="primary" href={route(CreateService)} state={{
            previousUrl,
          }}
        >
          Ajouter un service
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>

    <ServiceTable previousUrl={previousUrl} services={filteredServices} currentPage={currentPage} />
  </Layout>;
}, ROUTE_LIST_SERVICES);

export default Page;

/**
 * Sort service by name, alphabetically.
 */
function sortServices(services: Service[]) {
  const toSort = Array.from(services);
  return toSort.sort((a, b) => a.name.localeCompare(b.name));
}
