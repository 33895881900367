export function renderDuration(duration: number): string {
  return `${hoursFromDuration(duration)} h ${String(minutesFromDuration(duration)).padStart(2, '0')}`;
}

export function minutesFromDuration(duration: number): number {
  return duration % 60;
}

export function hoursFromDuration(duration: number): number {
  return Math.floor(duration / 60);
}

export function toDuration(hours: number, minutes: number): number {
  return hours * 60 + minutes;
}
