import { useDocumentTitle } from '@app/hooks/useDocumentTitle';
import { declareMemberRoute } from '@app/router/router';
import { useAuthContext } from '@app/hooks/useAuthContext';
import Link from '@app/components/Router/Link';
import { route } from '@app/router/generator';
import Logout from '@app/pages/Auth/Logout';
import Login from '@app/pages/Auth/Login';

export default declareMemberRoute(function Admin() {
  useDocumentTitle('Admin');

  const { profile, authenticated } = useAuthContext();

  return <div>
    <h1 className="text-3xl">Member</h1>

    {profile &&
      <p className="mt-4">Welcome, {profile.email}!</p>
    }
    {authenticated
      ? <Link to={route(Logout)} className="mt-4">Logout</Link>
      : <Link to={route(Login)} className="mt-4">Login</Link>
    }

  </div>;
}, '/');
