import styled from '@emotion/styled';
import tw from 'twin.macro';
import { useAuthContext } from '@app/hooks/useAuthContext';
import { route } from '@app/router/generator';
import Search from '@app/pages/Member/Search/Search';
import ListMembers from '@app/pages/Admin/Member/ListMembers/ListMembers';
import ExchangeList from '@app/pages/Admin/Exchange/ListExchanges/ListExchanges';
import ListServices from '@app/pages/Admin/Service/ListServices/ListServices';
import Profile from '@app/pages/User/Profile/Profile';
import Logout from '@app/pages/Auth/Logout';
import PrimaryNavLink from '@app/components/UI/Navbar/PrimaryNavLink';
import ListCategories from '@app/pages/Admin/Category/ListCategories/ListCategories';
import Exchanges from '@app/pages/User/Exchange/ListExchanges/ListMyExchanges';

interface PrimaryNavProps {
  setOpened: (opened: boolean) => void
}

export default function PrimaryNav({
  setOpened,
} : PrimaryNavProps) {
  const { isAdmin } = useAuthContext();

  return <PrimaryNav.Container>
    <PrimaryNav.List>
      <PrimaryNav.Entry>
        <PrimaryNavLink to={route(Search)} setOpened={setOpened}>Rechercher un service</PrimaryNavLink>
      </PrimaryNav.Entry>

      {isAdmin && <PrimaryNav.Entry>
        <PrimaryNavLink to={route(ListMembers)} setOpened={setOpened}>Membres</PrimaryNavLink>
      </PrimaryNav.Entry>}
      {isAdmin && <PrimaryNav.Entry>
        <PrimaryNavLink to={route(ExchangeList)} setOpened={setOpened}>Echanges</PrimaryNavLink>
      </PrimaryNav.Entry>}
      {isAdmin && <PrimaryNav.Entry>
        <PrimaryNavLink to={route(ListServices)} setOpened={setOpened}>Services</PrimaryNavLink>
      </PrimaryNav.Entry>}
      {isAdmin && <PrimaryNav.Entry>
        <PrimaryNavLink to={route(ListCategories)} setOpened={setOpened}>Categories</PrimaryNavLink>
      </PrimaryNav.Entry>}

      <PrimaryNav.UserEntry>
        <PrimaryNavLink to={route(Profile)} setOpened={setOpened}>Mon profil</PrimaryNavLink>
      </PrimaryNav.UserEntry>
      <PrimaryNav.UserEntry>
        <PrimaryNavLink to={route(Exchanges)} setOpened={setOpened}>Mes échanges</PrimaryNavLink>
      </PrimaryNav.UserEntry>
      <PrimaryNav.UserEntry>
        <PrimaryNavLink to={route(Logout)} setOpened={setOpened}>Se déconnecter</PrimaryNavLink>
      </PrimaryNav.UserEntry>

    </PrimaryNav.List>
  </PrimaryNav.Container>;
}


PrimaryNav.Container = tw.div`
  flex-1
  h-fit
  lg:h-full
`;

PrimaryNav.List = tw.ul`
  items-start
  lg:h-full
`;

PrimaryNav.Entry = styled(tw.li`
  w-full
  lg:w-fit
  lg:h-full
  flex
`)`
`;

PrimaryNav.UserEntry = tw.li`
  w-full
  lg:w-fit
  lg:hidden
  lg:h-full
`;
