import React from 'react';
import Select from '@app/components/UI/Form/Select';

type Props = Omit<React.ComponentProps<typeof Select<number>>, 'options'> & {
  minYear: number
  maxYear: number
}

export default function SelectYear({
  id,
  label,
  value,
  onChangedValue,
  required,
  errors,
  minYear,
  maxYear,
}: Props) {

  return <Select<number>
    id={id}
    label={label}
    options={[ ...Array((maxYear - minYear)).keys() ].map(i => ({ label: String(minYear + i), value: minYear + i }))}
    value={value}
    onChangedValue={onChangedValue}
    required={required}
    errors={errors}
  />;

}
