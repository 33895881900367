import { ApolloCache } from '@apollo/client';
import ListCategoriesQuery from '@graphql/query/category/ListCategories.graphql';

interface CategoryRef {
  uid: string
}

interface Store {
  Category: {
    list: CategoryRef[]
  }
}

/**
 * Update Apollo store on new service created.
 * Should make the new item visible on the listing.
 *
 * @see https://www.apollographql.com/docs/react/caching/cache-interaction/#using-graphql-queries
 */
export function onCreateCategory(
  store: ApolloCache<unknown>,
  category: CategoryRef,
) {
  const query = ListCategoriesQuery;
  // Attempt to read the data from the cache:
  const previousData = store.readQuery<Store>({ query });

  if (!previousData) {
    // If no data was fetched yet, we don't need to do anything
    return;
  }

  const data = {
    ...previousData,
    Category: {
      ...previousData.Category,
      // Append the newly created service to the list:
      list: [...previousData.Category.list, category],
    },
  };

  // Write the data back to the cache:
  store.writeQuery({ query, data });
}
