import { useLocation } from 'react-router-dom';
import Link from '@app/components/Router/Link';
import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import tw from 'twin.macro';

interface Props {
  to: string
  setOpened: (opened: boolean) => void
}

interface StyledLinkProps {
  active: boolean
}

export default function PrimaryNavLink({
  to,
  setOpened,
  children,
}: PropsWithChildren<Props>) {
  const location = useLocation();
  const currentPath = location.pathname;

  return <StyledLink
    active={currentPath === to}
    to={to}
    onClickCapture={(event) => {
      setOpened(false);
      event.currentTarget.blur();
    }}>
    {children}
  </StyledLink>;
}

const StyledLink = styled(Link, {
  // https://emotion.sh/docs/styled#customizing-prop-forwarding
  shouldForwardProp: (prop) => !['active'].includes(prop),
})<StyledLinkProps>(({ active }) => [
  tw`
    flex
  `,
  active && tw`
  hover:bg-transparent
  font-bold
  relative
  lg:(after:(
    absolute
    -bottom-[1px]
    w-12
    left-0
    h-1
    bg-primary
  ))`,
]);
