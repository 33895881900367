import { Children, FunctionComponent, ReactElement } from 'react';
import { isOfType } from '@app/utils/types';

type EmotionWrappedComponent = {
  props: {
    __EMOTION_TYPE_PLEASE_DO_NOT_USE__: FunctionComponent
  }
} & FunctionComponent;

/**
 * Relies on JSX specificities to identify a slot of a given type by checking the type property.
 *
 * @see https://sandroroth.com/blog/react-slots
 */
function ofType(slotType: FunctionComponent): (child: ReactElement<unknown, FunctionComponent>) => boolean {
  return (child) => {
    // handle teh case the slot is an emotion wrapped component (when using the css or tw prop on it)
    if (
      isOfType<EmotionWrappedComponent>(child, 'props') &&
      child?.props.__EMOTION_TYPE_PLEASE_DO_NOT_USE__ !== undefined
    ) {
      // The __EMOTION_TYPE_PLEASE_DO_NOT_USE__ property holds the original component type.
      // We're not supposed to use it, so we may find another solution later.
      // Perhaps using a dedicated <Slot /> component with a mandatory __slotName property
      // instead of relying on the component function for comparison.
      return child?.props.__EMOTION_TYPE_PLEASE_DO_NOT_USE__ === slotType;
    }

    return child.type === slotType;
  };
}

/**
 * Find a slot of a given type in the children of a component.
 */
export function findSlotOfType(children, slotType: FunctionComponent): ReactElement|null {
  return Children.toArray(children).find(ofType(slotType)) as ReactElement|null;
}
